import * as React from "react";
export const LocationIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || 12}
    height={height || 12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_368_14656)">
      <path
        d="M6.00001 3C5.60444 3 5.21776 3.1173 4.88886 3.33706C4.55996 3.55682 4.30362 3.86918 4.15224 4.23463C4.00087 4.60009 3.96126 5.00222 4.03843 5.39018C4.1156 5.77814 4.30608 6.13451 4.58579 6.41421C4.86549 6.69392 5.22186 6.8844 5.60982 6.96157C5.99779 7.03874 6.39992 6.99913 6.76537 6.84776C7.13083 6.69638 7.44318 6.44004 7.66295 6.11114C7.88271 5.78224 8.00001 5.39556 8.00001 5C8.00001 4.46957 7.7893 3.96086 7.41422 3.58579C7.03915 3.21071 6.53044 3 6.00001 3ZM6.00001 6C5.80222 6 5.60888 5.94135 5.44443 5.83147C5.27998 5.72159 5.15181 5.56541 5.07612 5.38268C5.00044 5.19996 4.98063 4.99889 5.01922 4.80491C5.0578 4.61093 5.15304 4.43275 5.2929 4.29289C5.43275 4.15304 5.61093 4.0578 5.80491 4.01921C5.9989 3.98063 6.19996 4.00043 6.38269 4.07612C6.56542 4.15181 6.72159 4.27998 6.83148 4.44443C6.94136 4.60888 7.00001 4.80222 7.00001 5C7.00001 5.26522 6.89465 5.51957 6.70711 5.70711C6.51958 5.89464 6.26522 6 6.00001 6Z"
        fill={color || "currentColor"}
      />
      <path
        d="M6.00016 12C5.57913 12.0022 5.16371 11.9034 4.78869 11.712C4.41367 11.5207 4.08996 11.2422 3.84466 10.9C1.93916 8.27152 0.972656 6.29552 0.972656 5.02652C0.972656 3.69315 1.50234 2.41438 2.44518 1.47154C3.38802 0.528705 4.66678 -0.000976562 6.00016 -0.000976562C7.33354 -0.000976562 8.6123 0.528705 9.55514 1.47154C10.498 2.41438 11.0277 3.69315 11.0277 5.02652C11.0277 6.29552 10.0612 8.27152 8.15566 10.9C7.91036 11.2422 7.58665 11.5207 7.21163 11.712C6.8366 11.9034 6.42119 12.0022 6.00016 12V12ZM6.00016 1.09052C4.95637 1.09171 3.95567 1.50689 3.21759 2.24496C2.47952 2.98303 2.06435 3.98373 2.06316 5.02752C2.06316 6.03252 3.00966 7.89102 4.72766 10.2605C4.87351 10.4614 5.06484 10.6249 5.28602 10.7377C5.50719 10.8504 5.75191 10.9092 6.00016 10.9092C6.24841 10.9092 6.49313 10.8504 6.7143 10.7377C6.93548 10.6249 7.12681 10.4614 7.27266 10.2605C8.99066 7.89102 9.93716 6.03252 9.93716 5.02752C9.93597 3.98373 9.5208 2.98303 8.78273 2.24496C8.04465 1.50689 7.04395 1.09171 6.00016 1.09052V1.09052Z"
        fill={color || "currentColor"}
      />
    </g>
    <defs>
      <clipPath id="clip0_368_14656">
        <rect width={12} height={12} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
