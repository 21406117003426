/* eslint-disable react-hooks/exhaustive-deps */
// Library Imports
import React, { Fragment, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom';

// Local Imports
import { publicRoutes, adminPrivateRoutes, userPrivateRoutes } from './config';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import ErrorPage from '../panel/ErrorPage';
import { useSelector } from 'react-redux';
import { useSocket } from '../redux/socket/socket';

const Router = () => {
    const user = useSelector((state) => state?.user?.user);
    const { sendCommand, isSocketReady } = useSocket();
    const privateRoutes = user?.all_user_permissions?.includes('superadmin') ? adminPrivateRoutes : userPrivateRoutes;
    useEffect(() => {
        if (isSocketReady) {
            sendCommand('fetch_channels');
        }
    }, [isSocketReady])
    return (
        <Fragment>
            <Routes>
                {/* Private Routes */}
                <Route element={<PrivateRoutes />}>
                    {privateRoutes?.map((route, index) => (
                        route.child ? (
                            <Route path={route.path} element={route.element} key={index}>
                                {route.child.map((childRoute, index) => (
                                    <Route path={childRoute.path} element={childRoute.element} key={index} />
                                ))}
                            </Route>
                        ) : <Route path={route.path} element={route.element} key={index} />
                    ))}
                </Route>

                {/* Public Routes */}
                <Route element={<PublicRoutes />}>
                    {publicRoutes?.map((route, index) => (
                        <Route path={route.path} element={route.element} key={index} />
                    ))}
                </Route>

                {/* Not Found Route */}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </Fragment>
    );
};

export default Router;