/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScrollShadow } from "@nextui-org/react";

// Local Imports
import {
  AuthLayout,
  Button,
  Stepper,
  showToast,
} from "../../../components/shared";
import { assets } from "../../../assets/images";
import {
  isEmpty,
  isError,
  validateEmail,
  validatePassword,
} from "../../../utils/validations";
import SignupForm from "./SignupForms/SignupForm";
import RolesInterests from "./SignupForms/RolesInterests";
import Locations from "./SignupForms/Locations";
import { signupFunction } from "../../../redux/reducers/authReducers/authReducers";
import { rolesAndInterestsFunction } from "../../../redux/reducers/authReducers/rolesInterestsReducer";
import { statesFunction } from "../../../redux/reducers/authReducers/statesReducer";

// Signup Form Initial Data
const initialSignupFormData = {
  personalDetails: {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    newPassword: "",
    confirmPassword: "",
  },
  rolesInterests: {
    other: "",
    roles: [],
    interests: [],
    user_interest: "",
  },
  locations: {
    currentState: "",
    currentCity: "",
    currentZipCode: "",
    operatedState: "",
    operatedCity: "",
    operatedZipCode: "",
  },
};

// Signup Form Errors Initial Data
const initialSignupFormErrors = {
  personalDetails: {
    fname: "",
    lname: "",
    email: "",
    phone: "",
    newPassword: "",
    confirmPassword: "",
  },
  rolesInterests: {
    other: "",
  },
  locations: {
    currentState: "",
    currentCity: "",
    currentZipCode: "",
    operatedState: "",
    operatedCity: "",
    operatedZipCode: "",
  },
};

const obj = {
  1: "personalDetails",
  2: "rolesInterests",
  3: "locations",
};

const Signup = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  // States
  const [zipcodeError, setZipcodeError] = useState({
    currentZipCode: "",
    operatedZipCode: "",
  });

  const [signupFormData, setSignupFormData] = useState(initialSignupFormData);
  const [signupFormErrors, setSignupFormErrors] = useState(
    initialSignupFormErrors
  );
  const [statesListing, setStatesListing] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
// Checkbox state for Agree terms and conditions 
   const [checked, setChecked] = useState(false);
  // Get Roles Interests
  const getRolesInterests = async () => {
    const rolesAndInterest = await dispatch(rolesAndInterestsFunction());

    // Create Roles Array
    const roles = await rolesAndInterest?.payload?.roles?.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr?.id,
          name: curr?.name?.toLowerCase(),
          label: curr?.name,
          value: false,
        },
      ],
      []
    );

    // Create Inetrest Array
    const interests = await rolesAndInterest?.payload?.interests?.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr?.id,
          name: curr?.name?.toLowerCase(),
          label: curr?.name,
          value: false,
        },
      ],
      []
    );

    // Set States
    setSignupFormData((prev) => ({
      ...prev,
      rolesInterests: {
        ...prev?.rolesInterests,
        roles,
        interests,
      },
    }));

    // Get States
    const statesList = await dispatch(statesFunction());
    setStatesListing(statesList?.payload);
  };

  // Use Layout Effect
  useLayoutEffect(() => {
    getRolesInterests();
  }, []);

  // Handle Form Data Change
  const handleFormDataChange = (event) => {
    const { name, value } = event.target;

    if (name === "currentZipCode" || name === "operatedZipCode") {
      setZipcodeError({});
    }

    // Check for only 20 characters in phone number
    if (name === "phone" && value.length > 14) return;

    // Reset Error
    setSignupFormErrors((prev) => ({
      ...prev,
      [obj[activeStep]]: {
        ...prev[obj[activeStep]],
        [name]: "",
      },
    }));

    if (name === "newPassword") {
      setPasswordCriteria({
        minLength: value.length >= 8,
        hasUppercase: /[A-Z]/.test(value),
        hasLowercase: /[a-z]/.test(value),
        hasNumber: /[0-9]/.test(value),
        hasSpecialChar: /[!@#$%^&*()_\-+=<>?{}[\]~`|\\:;"',./]/.test(value),
      });
    }
    // Set Data
    setSignupFormData((prev) => ({
      ...prev,
      [obj[activeStep]]: {
        ...prev[obj[activeStep]],
        [name]: value,
      },
    }));
  };

  // Create Payload
  const createPayload = () => {
    return {
      first_name: signupFormData?.personalDetails?.fname,
      last_name: signupFormData?.personalDetails?.lname,
      email: signupFormData?.personalDetails?.email,
      password: signupFormData?.personalDetails?.newPassword,
      phone_no: signupFormData?.personalDetails?.phone,
      location: {
        city: signupFormData?.locations?.currentCity,
        postalcode: signupFormData?.locations?.currentZipCode,
      },
      operated_location: {
        city: signupFormData?.locations?.operatedCity,
        postalcode: signupFormData?.locations?.operatedZipCode,
      },
      role_ids: signupFormData?.rolesInterests?.roles
        ?.filter((item) => item?.value === true)
        ?.map((item) => item?.id),
      user_roles: [signupFormData?.rolesInterests?.other],
      interest_ids: signupFormData?.rolesInterests?.interests
        ?.filter((item) => item?.value === true)
        ?.map((item) => item?.id),
      user_interest: [signupFormData?.rolesInterests.user_interest],
    };
  };
  // Handle Signup
  const handleSignup = async (e) => {
    e.preventDefault();

    // Step 1
    if (activeStep === 1) {
      let errors = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        newPassword: "",
        confirmPassword: "",
      };

      // Empty check
      if (isEmpty(signupFormData?.personalDetails)) {
        return;
      }

      // Validate Email
      if (!validateEmail(signupFormData?.personalDetails?.email)) {
        errors.email = "Invalid Email";
      }

      // Validate New Password
      if (!validatePassword(signupFormData?.personalDetails?.newPassword)) {
        errors.newPassword =
          "Invalid Password. Please ensure your password meets the following criteria.";
      }

      // Validate Confirm Password
      if (!validatePassword(signupFormData?.personalDetails?.confirmPassword)) {
        errors.confirmPassword =
          "Invalid Password. Please ensure your password meets the following criteria.";
      }

      if (
        validatePassword(signupFormData?.personalDetails?.newPassword) &&
        validatePassword(signupFormData?.personalDetails?.confirmPassword)
      ) {
        if (
          signupFormData?.personalDetails?.newPassword !==
          signupFormData?.personalDetails?.confirmPassword
        ) {
          errors.confirmPassword = "Your password does not match.";
        }
      }

      if (isError(errors)) {
        setSignupFormErrors((prev) => ({
          ...prev,
          personalDetails: errors,
        }));
      } else {
        setActiveStep((prev) => prev + 1);
      }
    }

    // Step 2
    if (activeStep === 2) {
      if (checkRolesAndInterests()) {
        setActiveStep((prev) => prev + 1);
      }
    }

    // Step 3
    if (activeStep === 3) {
      if (isEmpty(signupFormData?.locations)) {
        return;
      } else {
        const payload = createPayload();
        const data = await dispatch(signupFunction(payload));

        if (data?.meta?.requestStatus === "rejected") {
          const errorMessages = data?.payload
            ? data?.payload
                .map((error) => {
                  if (error.zip_code) {
                    setZipcodeError({
                      currentZipCode: error.zip_code,
                    });
                  }
                  if (error.operated_zip_code) {
                    setZipcodeError({
                      operatedZipCode: error.operated_zip_code,
                    });
                  }
                  // More specific error formatting can go here.
                  return `Error: ${Object.values(error).join(", ")}`;
                })
                .join("\n") // Join error messages into a multiline string.
            : "Something went wrong";

          showToast(errorMessages, "error", {
            position: "top-center",
          });
          return;
        }

        navigate("/suggestions");
      }
    }
  };

  // Check Roles and Interests
  const checkRolesAndInterests = () => {
    let roles = signupFormData?.rolesInterests?.roles?.some(
      (item) => item?.value === true
    );
    let interests = signupFormData?.rolesInterests?.interests?.some(
      (item) => item?.value === true
    );
    return (
      (roles || signupFormData?.rolesInterests?.other !== "") &&
      (interests || signupFormData?.rolesInterests?.user_interest !== "")
    );
  };

  return (
    <AuthLayout>
      <div className="w-full h-full center">
        <div className="bg-white sm:rounded-lg w-full sm:w-[30rem] sm:h-fit h-full py-3 shadow-lg">
          <div className="max-h-[100vh] sm:max-h-[90vh] px-3">
            <div className="font-nunito flex items-baseline justify-center mb-4">
              <img src={assets.logo} alt="logo" className="size-12" />
              <p className="text-gold mt-3 text-2xl font-bold tracking-wide w-fit">
                ealNest.
              </p>
            </div>

            <Stepper
              steps={3}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              previousArrow={true}
            />

            <ScrollShadow
              offset={8}
              hideScrollBar
              size={80}
              className="mt-3 h-[calc(100vh-200px)] sm:h-[calc(90vh-200px)] overflow-auto no-scrollbar"
            >
              {activeStep === 1 ? (
                <SignupForm
                  onChange={handleFormDataChange}
                  formData={signupFormData?.personalDetails}
                  formErrors={signupFormErrors?.personalDetails}
                  passwordCriteria={passwordCriteria}
                  onSubmit={handleSignup}
                />
              ) : null}
              {activeStep === 2 ? (
                <RolesInterests
                  onChange={handleFormDataChange}
                  formData={signupFormData?.rolesInterests}
                  setFormData={setSignupFormData}
                  formErrors={signupFormErrors?.rolesInterests}
                  onSubmit={handleSignup}
                />
              ) : null}
              {activeStep === 3 ? (
                <Locations
                  statesList={statesListing}
                  onChange={handleFormDataChange}
                  formData={signupFormData?.locations}
                  setFormData={setSignupFormData}
                  formErrors={signupFormErrors?.locations}
                  onSubmit={handleSignup}
                  zipcodeError={zipcodeError}
                  checked={checked}
                  setChecked={setChecked}
                />
              ) : null}
            </ScrollShadow>

            <div className="bg-white pt-2">
              <Button
                variant={"filled"}
                color={"primary"}
                loading={loading}
                disabled={
                  activeStep === 1
                    ? isEmpty(signupFormData?.personalDetails) ||
                      isError(signupFormErrors?.personalDetails)
                    : activeStep === 2
                    ? !checkRolesAndInterests()
                    : activeStep === 3
                    ? isEmpty(signupFormData?.locations) || !checked
                    : false
                }
                onClick={handleSignup}
              >
                {activeStep < 3 ? "Next" : "Get Started"}
              </Button>
              <div className="center gap-1 mt-1">
                <p className="text-darkgrey text-sm">
                  Already have an account?
                </p>
                <p
                  className="text-primary text-sm hover:underline cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Log in
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Signup;
