import * as React from "react";

export const Filter = ({ width, height, ...props }) => (
  <svg
    width={24 || width}
    height={24 || height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50039 3.90002C6.54908 3.90002 5.74552 4.53258 5.48735 5.40002H4.00039L3.90307 5.40788C3.61798 5.45438 3.40039 5.70179 3.40039 6.00002C3.40039 6.3314 3.66902 6.60002 4.00039 6.60002H5.48735C5.74552 7.46747 6.54908 8.10002 7.50039 8.10002C8.4517 8.10002 9.25526 7.46747 9.51343 6.60002H20.0004L20.0977 6.59217C20.3828 6.54567 20.6004 6.29826 20.6004 6.00002C20.6004 5.66865 20.3318 5.40002 20.0004 5.40002H9.51343C9.25526 4.53258 8.4517 3.90002 7.50039 3.90002ZM20.0004 17.4C20.3318 17.4 20.6004 17.6687 20.6004 18C20.6004 18.2983 20.3828 18.5457 20.0977 18.5922L20.0004 18.6H11.5134C11.2553 19.4675 10.4517 20.1 9.50039 20.1C8.54908 20.1 7.74552 19.4675 7.48735 18.6H4.00039C3.66902 18.6 3.40039 18.3314 3.40039 18C3.40039 17.7018 3.61798 17.4544 3.90307 17.4079L4.00039 17.4H7.48735C7.74552 16.5326 8.54908 15.9 9.50039 15.9C10.4517 15.9 11.2553 16.5326 11.5134 17.4H20.0004ZM20.6004 12C20.6004 11.6687 20.3318 11.4 20.0004 11.4H18.5134C18.2553 10.5326 17.4517 9.90002 16.5004 9.90002C15.5491 9.90002 14.7455 10.5326 14.4874 11.4H4.00039L3.90307 11.4079C3.61798 11.4544 3.40039 11.7018 3.40039 12C3.40039 12.3314 3.66902 12.6 4.00039 12.6H14.4874C14.7455 13.4675 15.5491 14.1 16.5004 14.1C17.4517 14.1 18.2553 13.4675 18.5134 12.6H20.0004L20.0977 12.5922C20.3828 12.5457 20.6004 12.2983 20.6004 12ZM8.40039 6.00003C8.40039 5.50297 7.99744 5.10003 7.50039 5.10003C7.00333 5.10003 6.60039 5.50297 6.60039 6.00003C6.60039 6.49708 7.00333 6.90003 7.50039 6.90003C7.99744 6.90003 8.40039 6.49708 8.40039 6.00003ZM10.4004 18C10.4004 17.503 9.99744 17.1 9.50039 17.1C9.00333 17.1 8.60039 17.503 8.60039 18C8.60039 18.4971 9.00333 18.9 9.50039 18.9C9.99744 18.9 10.4004 18.4971 10.4004 18ZM17.4004 12C17.4004 11.503 16.9974 11.1 16.5004 11.1C16.0033 11.1 15.6004 11.503 15.6004 12C15.6004 12.4971 16.0033 12.9 16.5004 12.9C16.9974 12.9 17.4004 12.4971 17.4004 12Z"
      fill="currentColor"
    />
  </svg>
);
