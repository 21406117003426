import React from "react";
import { assets } from "../../../assets/images";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white sm:rounded-lg w-full h-full py-3 shadow-lg overflow-auto">
      <div className="max-h-[100vh] sm:max-h-[100vh] px-3">
        <div className="font-nunito flex items-baseline justify-center my-4">
          <img src={assets.logo} alt="logo" className="size-12" />
          <p className="text-gold mt-3 text-2xl font-bold tracking-wide w-fit">
            ealNest.
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading pt-2">Kingsmen Capital Privacy Policy</p>
        </div>
        <div className="policy-container">
          <p className="policy-text font-bold text-lg">
            Last modified: 11-3-2024
          </p>
          <p className="policy-heading">Introduction</p>
          <p className="policy-text">
            <span className="font-bold text-gray-800">
              Kingsmen Capital, LLC.
            </span>{" "}
            ("Company" or "We") respect your privacy and are committed to
            protecting it through our compliance with this policy. This policy
            describes the types of information we may collect from you or that
            you may provide when you visit or access Kingsmen Capital websites
            ("Website") and our practices for collecting, using, maintaining,
            protecting, and disclosing that information. This policy applies to
            information we collect:
          </p>
          <ul className="policy-list">
            <li>On our Websites.</li>
            <li>
              In email, text, and other electronic messages between you and
              Kingsmen Capital.
            </li>
            <li>
              Through any mobile and desktop applications you download from a
              digital platform or online marketplace, which provide dedicated
              non-browser-based interaction between you and Kingsmen Capital.
            </li>
          </ul>
          <p className="policy-text mt-4">
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. By accessing or using our Website, you agree to
            this privacy policy.
          </p>
          <p className="policy-text mt-3">
            This policy may change from time to time (
            <span className="italic">see Changes to Our Privacy Policy</span>
            ). Your continued use of our Website after we make changes is deemed
            to be acceptance of those changes, so please check the policy
            periodically for updates.
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading">Children Under the Age of 13</p>
          <p className="policy-text">
            Our Websites are not intended for children under 13 years of age. No
            one under age 13 may provide any information to or on our Website.
            We do not knowingly collect personal information from children under
            13. If you are under 13, do not use or provide any information on
            our Website or through any of its features, register on our Website,
            make any purchases through our Website, or provide any information
            about yourself to us, including your name, address, telephone
            number, email address, or any screen name or user name you may use.
          </p>
          <p className="policy-text mt-4">
            If we learn we have collected or received personal information from
            a child under 13 without verification of parental consent, we will
            delete that information. If you believe we might have any
            information from or about a child under 13, please contact us at
            <a
              href="mailto:info@dealnestio.com"
              className="text-blue-600 hover:underline"
            >
              info@dealnestio.com
            </a>
            .
          </p>
          <p className="policy-text mt-4">
            <span className="font-bold text-gray-800">
              California residents under 16 years of age
            </span>{" "}
            may have additional rights regarding the collection and sale of
            their personal information. Please see
            <a
              href="#california-rights"
              className="text-blue-600 hover:underline"
            >
              Your California Privacy Rights
            </a>{" "}
            for more information.
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading">
            Information We Collect About You and How We Collect It
          </p>

          <p className="policy-text mb-4">
            We collect several types of information from and about users of our
            Website, including:
          </p>

          <ul className="policy-list mb-4">
            <li>
              By which you may be personally identified, such as name, age,
              birthday, gender, height, weight, postal address, e-mail address,
              telephone number, or any other identifier by which you may be
              contacted online or offline ("personal information").
            </li>
            <li>
              About your internet connection, the equipment you use to access
              our Website, and usage details.
            </li>
          </ul>

          <p className="policy-text mb-4">We collect this information:</p>

          <ul className="policy-list mb-4">
            <li>Directly from you when you provide it to us.</li>
            <li>
              Automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies.
            </li>
            <li>From your employer and their databases.</li>
            <li>From third parties, for example, our business partners.</li>
          </ul>

          <p className="text-lg font-bold text-gray-900 mb-4">
            Information You Provide to Us
          </p>
          <ul className="policy-list policy-text mb-4">
            <li>
              Information that you provide by filling in forms on our Website,
              including account access, filing reports, or requesting further
              services. This includes information provided at the time of
              accessing your account or reporting a problem with our Website.
            </li>
            <li>
              Records and copies of correspondence (including name, email
              addresses, and phone numbers), if you contact us.
            </li>
            <li>Your responses to surveys for research purposes.</li>
            <li>
              Your search queries and customization of your interaction
              experience on our Website.
            </li>
            <li>
              Information required to automatically and manually populate
              document fields.
            </li>
          </ul>

          <p className="policy-text mb-4">
            You may provide information to be published or displayed ("posted")
            on public areas of our Website or transmitted to other users of our
            Website or third parties (collectively, "User Contributions"). Your
            User Contributions are posted at your own risk. Although you may set
            certain privacy settings by logging into your account, please be
            aware that no security measures are perfect. We cannot guarantee
            that your User Contributions will not be viewed by unauthorized
            persons.
          </p>

          <p className="text-lg font-bold text-gray-900 mb-4">
            Information We Collect Through Automatic Data Collection
            Technologies
          </p>

          <p className="policy-text mb-4">
            As you navigate and interact with our Website, we may use automatic
            data collection technologies to gather information about your
            equipment, browsing actions, and patterns, including:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>
              Details of your visits to our Website, including traffic data,
              location data, logs, and other communication data.
            </li>
            <li>
              Information about your computer and internet connection, including
              your IP address, operating system, and browser type.
            </li>
          </ul>

          <p className="policy-text mb-4">
            The information we collect automatically may include personal
            information. It helps us improve our Website and deliver a better,
            more personalized service, including:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>Estimating audience size and usage patterns.</li>
            <li>
              Storing information about your preferences to customize the
              Website according to your interests.
            </li>
            <li>Speeding up your searches.</li>
            <li>Recognizing you when you return to the Website.</li>
            <li>Providing data analysis to improve current offerings.</li>
            <li>
              Creating models for future product improvements and enhancements.
            </li>
          </ul>

          <p className="policy-text mb-4">
            The technologies we use for automatic data collection include:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>
              <span className="font-bold">Cookies:</span> Small files placed on
              your computer’s hard drive. You may refuse cookies by adjusting
              your browser settings, but this may limit access to some Website
              features.
            </li>
            <li>
              <span className="font-bold">Flash Cookies:</span> Used to collect
              and store preferences and navigation data. Flash cookies are not
              managed by browser settings.
            </li>
            <li>
              <span className="font-bold">Web Beacons:</span> Small electronic
              files on Website pages or emails that collect statistics, such as
              user visits or email interactions.
            </li>
            <li>
              <span className="font-bold">Cookieless Tracking:</span> Uses IP
              tracking and colorless images to identify Website visitors.
            </li>
          </ul>
        </div>

        <div className="policy-container">
          <p className="policy-heading">
            Third-Party Use of Cookies and Other Tracking Technologies
          </p>
          <p className="policy-text mb-4">
            Some content or applications, including advertisements, on our
            Website are served by third parties, including advertisers, ad
            networks and servers, content providers, and application providers.
            These third parties may use cookies alone or in conjunction with web
            beacons or other tracking technologies to collect information about
            you when you use our website.
          </p>
          <p className="policy-text mb-4">
            The information they collect may be associated with your personal
            information or they may collect information, including personal
            information, about your online activities over time and across
            different websites and other online services. They may use this
            information to provide you with interest-based (behavioral)
            advertising or other targeted content.
          </p>
          <p className="policy-text mb-4">
            We do not control these third parties' tracking technologies or how
            they may be used. If you have any questions about an advertisement
            or other targeted content, you should contact the responsible
            provider directly. For information about how you can opt out of
            receiving targeted advertising from many providers, see{" "}
            <span className="font-semibold">
              Choices About How We Use and Disclose Your Information
            </span>
            .
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading">How We Use Your Information</p>

          <p className="policy-text mb-4">
            We use information that we collect about you or that you provide to
            us, including any personal information:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>To present our Website and its contents to you.</li>
            <li>
              To provide you with information, products, or services that you
              request from us.
            </li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>
              To provide you with notices about your subscription, including
              expiration and renewal notices.
            </li>
            <li>
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To notify you about changes to our Website or any products or
              services we offer or provide through it.
            </li>
            <li>
              To allow you to participate in interactive features on our
              Website.
            </li>
            <li>
              To train models for data analysis & an improved user experience.
            </li>
            <li>
              To be fed through data pipelines for analysis and interpretation.
            </li>
            <li>To offer suggestions for preventative actions.</li>
            <li>To populate worker’s compensation claim documents.</li>
            <li>To offer as evidence in insurance and claim investigations.</li>
            <li>
              In any other way we may describe when you provide the information.
            </li>
          </ul>
        </div>

        <div className="policy-container">
          <p className="policy-heading">Disclosure of Your Information</p>

          <p className="policy-text mb-4">
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </p>

          <p className="policy-text mb-4">
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers, and other third parties we use
              to support our business.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Company’s assets, whether as a
              going concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which personal information held by Company about
              our Website users is among the assets transferred.
            </li>
            <li>
              To third parties to market their products or services to you if
              you have consented to/not opted out of these disclosures. We
              contractually require these third parties to keep personal
              information confidential and use it only for the purposes for
              which we disclose it to them. For more information, see Choices
              About How We Use and Disclose Your Information.
            </li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>
            <li>With your consent.</li>
          </ul>

          <p className="policy-text mb-4">
            We may also disclose your personal information:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>
              To enforce or apply our Terms of Service and other agreements.
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of Company, our customers, or
              others. This includes exchanging information with other companies
              and organizations for the purposes of fraud protection and credit
              risk reduction.
            </li>
          </ul>
        </div>

        <div className="policy-container">
          <p className="policy-heading">
            Choices About How We Use and Disclose Your Information
          </p>

          <p className="policy-text mb-4">
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
          </p>

          <ul className="policy-list policy-text mb-4">
            <li>
              <span className="font-semibold">
                Tracking Technologies and Advertising:
              </span>{" "}
              You can set your browser to refuse all or some browser cookies, or
              to alert you when cookies are being sent. To learn how you can
              manage your Flash cookie settings, visit the Flash player settings
              page on Adobe's website. If you disable or refuse cookies, please
              note that some parts of this site may then be inaccessible or not
              function properly.
            </li>
          </ul>

          <p className="policy-text mb-4">
            We do not control third parties' collection or use of your
            information to serve interest-based advertising. However, these
            third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative (<span className="font-semibold">"NAI"</span>) on the{" "}
            <a
              href="https://www.networkadvertising.org"
              target="_blank"
              rel="noopener noreferrer"
              className=".policy-link"
            >
              NAI's website
            </a>
            .
          </p>

          <p className="policy-text mb-4">
            California residents may have additional personal information rights
            and choices. Please see{" "}
            <span className="font-semibold">
              Your California Privacy Rights
            </span>{" "}
            for more information.
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading">
            Accessing and Correcting Your Information
          </p>

          <p className="policy-text mb-4">
            You can review and change your personal information by logging into
            our Website and visiting your account profile page. You can also
            change personal information on any relevant editable form, page, or
            document.
          </p>

          <p className="policy-text mb-4">
            If requiring further assistance, you may also send us an email at{" "}
            <a href="mailto:info@dealnestio.com" className=".policy-link">
              info@dealnestio.com
            </a>{" "}
            to request access to, correct, or delete any personal information
            that you have provided to us. We may not accommodate a request to
            change information if we believe the change would violate any law or
            legal requirement or cause the information to be incorrect.
          </p>

          <p className="policy-text mb-4">
            If you delete your User Contributions from our Website, copies of
            your User Contributions may remain viewable in cached and archived
            pages, or might have been copied or stored by other Website users.
            Proper access and use of information provided on our Website,
            including User Contributions, is governed by our{" "}
            <a href="/terms-and-condition" className=".policy-link">
              Terms of Service
            </a>
            .
          </p>

          <p className="policy-text">
            California residents may have additional personal information rights
            and choices. Please see{" "}
            <span className="font-semibold">
              Your California Privacy Rights
            </span>{" "}
            for more information.
          </p>
        </div>

        <div className="policy-container">
          <p className="policy-heading">Your California Privacy Rights</p>

          <p className="policy-text mb-4">
            If you are a California resident, California law may provide you
            with additional rights regarding our use of your personal
            information. To learn more about your California privacy rights,
            visit{" "}
            <a href="https://oag.ca.gov/privacy/ccpa" className=".policy-link">
              California Consumer Privacy Act (CCPA) | State of California -
              Department of Justice - Office of the Attorney General
            </a>
            .
          </p>

          <p className="policy-text mb-4">
            California's "Shine the Light" law (Civil Code Section § 1798.83)
            permits users of our App that are California residents to request
            certain information regarding our disclosure of personal information
            to third parties for their direct marketing purposes. To make such a
            request, please send an email to{" "}
            <a href="mailto:info@dealnestio.com" className=".policy-link">
              info@dealnestio.com
            </a>
            .
          </p>

          <p className="text-2xl font-extrabold text-gray-900 mt-8 mb-4">
            Data Security
          </p>

          <p className="policy-text mb-4">
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure.
          </p>

          <p className="policy-text mb-4">
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of our Website, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone.
          </p>

          <p className="policy-text mb-4">
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our Website. Any transmission of
            personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on our Website.
          </p>
        </div>

        <div className="policy-container">
          <p className="text-2xl font-extrabold text-gray-900 mb-4">
            Changes to Our Privacy Policy
          </p>

          <p className="policy-text mb-4">
            It is our policy to post any changes we make to our privacy policy
            on this page. The date the privacy policy was last revised is
            identified at the top of the page. You are responsible for ensuring
            we have an up-to-date active and deliverable email address for you,
            and for periodically visiting our Website and this privacy policy to
            check for any changes.
          </p>
        </div>

        <div className="policy-container pb-2">
          <p className="text-2xl font-extrabold text-gray-900 mb-4">
            Contact Information
          </p>

          <p className="policy-text mb-4">
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at:
            <a
              href="mailto:info@dealnestio.com"
              className=".policy-link hover:policy-link:hover"
            >
              info@dealnestio.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
