import React from "react";

// Local Imports
import { CustomModal } from "../../../../components/shared";

export const ProfileDeleteModal = ({
  deleteModal,
  setDeleteModal,
  loading,
}) => {
  return (
    <CustomModal
      title={`Delete ${deleteModal?.data?.name}`}
      open={deleteModal?.open}
      close={() => setDeleteModal({ open: false, data: null })}
      primaryButtonText="Delete"
      primaryButtonColor="danger"
      primaryButtonClick={() => {
        deleteModal?.data?.onClick(deleteModal?.data?.id);
      }}
      primaryButtonLoading={loading}
    >
      <p className="text-sm">
        Are you sure you want to delete this{" "}
        <span className="font-semibold">{deleteModal?.data?.name}</span>?
      </p>
    </CustomModal>
  );
};
