import React from "react";
import { useSelector } from "react-redux";

// Local Imports
import { NoDataFound, PartnerCard, Skeleton } from "../../../../components/shared";

export const NearestPartners = () => {
  const { partnersLoading, partners: partnersListing } = useSelector(
    (state) => state?.partners
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 base:grid-cols-3 gap-4 pb-6 mt-6">
      {partnersLoading ? (
        <>
          {Array.from({ length: 8 }, (_, index) => (
            <Skeleton key={index} className="w-full h-24 rounded-3xl" />
          ))}
        </>
      ) : (
        <>
          {partnersListing?.nearest_partners?.length > 0 ? (
            <>
              {partnersListing?.nearest_partners?.map((item) => {
                const location = [];
                location?.push(
                  item?.mailing_address?.city?.name +
                    ", " +
                    item?.mailing_address?.state?.name
                );

                return (
                  <PartnerCard
                    key={item?.id}
                    src=""
                    name={
                      (item?.first_name || "") + " " + (item?.last_name || "")
                    }
                    designation={item?.profession}
                    address={location?.filter(Boolean)?.join(", ")}
                    id={item?.id}
                  />
                );
              })}
            </>
          ) : (
          <NoDataFound text={'No Partners Found'} />
          )}
        </>
      )}
    </div>
  );
};
