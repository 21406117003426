import React from "react";
import { Progress } from "@nextui-org/react";

export const ProfileCard = ({
  iconSX,
  trackSX,
  title,
  indecatorSX,
  text,
  range,
  icon,
  onClick,
  showProgress = false,
  cardSX,
  textIcon,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex flex-col justify-between min-h-[183px] min-w-[191px] p-[16px] rounded-[20px] cursor-pointer bg-white select-none ${cardSX}`}
    >
      <div
        className={`w-[36px] h-[36px] rounded-full p-[5px] flex justify-center items-center ${iconSX}`}
      >
        {icon}
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-1">
          {textIcon ? textIcon : ""}
          <h6 className="font-nunito font-bold text-sm">{title}</h6>
        </div>
        <p className="font-nunito font-medium text-xs text-darkgrey pb-1 line-clamp-2">
          {text}
        </p>

        {showProgress ? (
          <Progress
            size="sm"
            aria-label="Loading..."
            classNames={{
              track: `h-[5px] ${trackSX}`,
              indicator: `h-[5px] ${indecatorSX}`,
            }}
            value={range}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ProfileCard;
