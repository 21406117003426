// Library Imports
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Local Imports
import { setAuthToken } from '../redux/network/https';
import { persistor, store } from '../redux/store';
import { logout } from '../redux/reducers/authReducers/authReducers';
import { format } from 'date-fns';

// Authentication Function
export const useIsAuthenticatedUser = () => {
  const user = useSelector((state) => state?.user?.user);

  const token = user?.token?.access;
  if (!token) {
    return { isAuthenticated: false, isResetPassword: user?.is_reset_password };
  }
  setAuthToken(token);
  return { isAuthenticated: true, isResetPassword: user?.is_reset_password };
};

// Logout Function
export const logoutFn = () => {
  const dispatch = store.dispatch;
  dispatch(logout());
  persistor.purge();
};

// Debounce
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Capitalize First Letter
export const capitalize = (string) => {
  if (!string?.trim()) return '';
  string = string?.toLowerCase();
  const capitalized = string?.split(' ')?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1));
  return capitalized?.join(' ');
}

export const formatDate = (date, dateFormat = 'MMM d, y') => {
  if (!date) return '';
  return format(date, dateFormat)
};

// Check Active Routes
export const checkRoute = (route) => {
  return window?.location.pathname?.split('/')[1] === route?.split('/')[1]
}

export function formatToReadableTime(dateString) {
  const date = new Date(dateString);

  // Extract hours and minutes
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const amPm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour format to 12-hour format
  hours = hours % 12 || 12;

  // Format the time
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${amPm}`;

  return formattedTime;
}