import React from "react";

// Local Imports
import { PlusIcon } from "../../../assets/icons";

export const AddMore = ({ text, onClick, disabled, size }) => {
  return (
    <div
      className={`vertical-center gap-3 w-fit ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      onClick={disabled ? null : onClick}
    >
      <div
        className={`${size || "size-10"} rounded-full ${
          disabled ? "bg-grey" : "bg-primary"
        } color-white flex justify-center items-center`}
      >
        <PlusIcon width={15} height={15} color="white" />
      </div>
      {text ? (
        <p
          className={`font-semibold text-sm ${
            disabled ? "text-grey" : "text-primary"
          }`}
        >
          {text}
        </p>
      ) : null}
    </div>
  );
};
