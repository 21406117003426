import React from "react";

// Local Imports
import { Skeleton } from "../../../../../components/shared";
import { capitalize } from "../../../../../utils/utils";

export const Recommendations = ({ loading, profileData }) => {
  const { recommendations } = profileData;

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {recommendations?.map((recommendation, index) => {
              const data = [];
              data.push(
                capitalize(recommendation?.relation),
                recommendation?.email || ""
              );
              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-24"
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold">
                      {recommendation?.referee}
                    </p>
                  </div>
                  <p className="text-sm text-grey">
                    {data?.filter(Boolean)?.join(" - ")}
                  </p>
                  <p className="text-sm text-grey">{recommendation?.phone}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
