import React, { useState } from 'react'
import { Avatar } from '@nextui-org/react'
import { CustomModal, CustomPopover } from '../shared';
import { DotsIcon, GroupChatIcon } from '../../assets/icons';

const ChatAreaHeader = ({
    chatAvatar,
    chatType,
    chatHeaderName,
    chatMembers,
    completeItem,
    activeChat,
    setActiveChat,
    deleteChat
}) => {
    const [openPopover, setOpenPopover] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    return (
        <React.Fragment>
            <div className='h-[70px] p-4 border-b flex items-center justify-between'>
                <div className="!w-[calc(100%-40px)] flex items-center gap-3 pr-2">
                    {activeChat && (
                        <button className="hover:bg-gray-100 rounded-full base:hidden" onClick={() => setActiveChat(null)}>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                    )}
                    {chatType === 'group' ?
                        <div className='w-10 h-10 min-w-10 rounded-full bg-mediumgrey/30 flex items-center justify-center'>
                            <GroupChatIcon className='!w-6 !h-6' />
                        </div> :
                        <div className='w-10 h-10'>
                            <Avatar
                                src={chatAvatar}
                                classNames={{
                                    base: "bg-mediumgrey/30",
                                    icon: "text-black/60",
                                }}
                            />
                        </div>}
                    <div className='flex flex-col w-[calc(100%-80px)]'>
                        <span className="font-medium truncate">{chatHeaderName}</span>
                        {chatType === 'group' && <span className="text-sm text-gray-500 truncate">{chatMembers?.join(', ')}</span>}
                    </div>
                </div>
                <CustomPopover
                    open={openPopover}
                    setOpen={() => setOpenPopover(!openPopover)}
                    trigger={
                        <div className="ml-auto  text-gray-400">
                            <DotsIcon />
                        </div>
                    }
                    placement='bottom-end'
                >
                    <div className="bg-white border border-gray-200 shadow-2xl rounded-md font-nunito text-sm flex flex-col">
                        <div className="vertical-center gap-2 cursor-pointer py-2 px-2 hover:bg-gray-100"
                            onClick={() => { setOpenDeleteModal(true); setOpenPopover(false) }}>
                            <p>Delete Chat</p>
                        </div>

                        <div className="vertical-center gap-2 cursor-pointer py-2 px-2 hover:bg-gray-100"
                            onClick={() => { setOpenPopover(false); setActiveChat(null) }}>
                            <p>Close Chat</p>
                        </div>
                    </div>
                </CustomPopover>
            </div>
            <CustomModal
                title={"Delete Chat"}
                open={openDeleteModal}
                close={() => setOpenDeleteModal(false)}
                showPrimaryButton={true}
                showSecondaryButton={true}
                primaryButtonColor='danger'
                primaryButtonText={'Delete Chat'}
                primaryButtonClick={() => { deleteChat(activeChat?.channel_id); setOpenDeleteModal(false) }}

                secondaryButtonText={'Cancel'}
                secondaryButtonClick={() => setOpenDeleteModal(false)}
            >
                <div className="">
                    <div className="flex flex-col gap-2">
                        <p className="font-nunito text-xl">Are you sure you want to delete this chat?</p>
                    </div>
                </div>
            </CustomModal>
        </React.Fragment>
    )
}

export default ChatAreaHeader
