import * as React from "react";
export const CloseIcon = (props) => (
    <svg
        width={props.width || 24}
        height={props.height || 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17.6565 6.34315L6.34277 17.6569"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
        <path
            opacity={0.5}
            d="M17.6565 17.6568L6.34277 6.34314"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
    </svg>
);
