export function TriangleIcon({ className, color }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            version='1'
            fill={color}
            viewBox='0 0 1280 1130'
            className={className}
        >
            <path
                d='M6266 11289c-200-27-402-141-536-301-38-46-432-718-1284-2194C892 2641 123 1309 88 1240 27 1119 7 1029 7 865c-1-115 3-154 21-220C119 318 378 78 709 16 784 2 1323 0 6400 0s5616 2 5691 16c331 62 590 302 681 629 18 66 22 105 21 220 0 164-20 254-81 375-21 41-756 1317-1633 2835S8953 7755 8304 8879c-817 1416-1196 2063-1234 2109-112 134-277 239-445 283-93 24-256 32-359 18z'
                transform='matrix(.1 0 0 -.1 0 1130)'
            ></path>
        </svg>
    );
}
