/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import {
  Divider,
  TextInput,
  CustomModal,
  showToast,
  SelectDropdown,
} from "../../../../components/shared";

import { isEmpty, isError, validateEmail } from "../../../../utils/validations";
import {
  getProfileDetailFunction,
  updateProfileDetailFunction,
} from "../../../../redux/reducers/userReducers/editProfileReducers/editProfileReducer";
import { rolesAndInterestsFunction } from "../../../../redux/reducers/authReducers/rolesInterestsReducer";
import {
  citiesFunction,
  statesFunction,
} from "../../../../redux/reducers/authReducers/statesReducer";

// ProfileDetails Initial Errors
const initialProfileErrors = {
  firstName: "",
  lastName: "",
  address: "",
  physicalAddress: "",
  profession: "",
  gender: "",
  birthday: "",
  phoneNumber: "",
  email: "",
};

export const EditProfile = ({
  loading,
  openProfileModal,
  setOpenProfileModal,
  profileData,
  setProfileData,
}) => {
  const dispatch = useDispatch();
  const { cityLoading } = useSelector((state) => state?.states);

  const [formErrors, setFormErrors] = useState(initialProfileErrors);
  const [statesListing, setStatesListing] = useState([]);
  const [currentCityList, setCurrentCityList] = useState([]);
  const [operatedCityList, setOperatedCityList] = useState([]);

  const getRolesInterests = async () => {
    const rolesAndInterest = await dispatch(rolesAndInterestsFunction());
    const statesList = await dispatch(statesFunction());

    // Create Roles Array
    const roles = await rolesAndInterest?.payload?.roles?.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr?.id,
          name: curr?.name?.toLowerCase(),
          label: curr?.name,
          value: false,
        },
      ],
      []
    );

    // Create Inetrest Array
    const interests = await rolesAndInterest?.payload?.interests?.reduce(
      (prev, curr) => [
        ...prev,
        {
          id: curr?.id,
          name: curr?.name?.toLowerCase(),
          label: curr?.name,
          value: false,
        },
      ],
      []
    );

    setProfileData((prev) => ({
      ...prev,
      roles: roles,
      interests: interests,
    }));

    // Set States
    setStatesListing(statesList?.payload);
  };

  // Use Layout Effect
  useLayoutEffect(() => {
    getRolesInterests();
  }, []);

  const handleCloseModal = () => {
    setOpenProfileModal(false);
    setFormErrors(initialProfileErrors);
  };

  const getProfileDetail = async () => {
    await dispatch(getProfileDetailFunction());
  };

  useLayoutEffect(() => {
    getProfileDetail();
  }, []);

  // Get Cities Listing
  const getCities = async (from) => {
    if (from === "current") {
      const citiesList = await dispatch(
        citiesFunction({ id: profileData?.currentState })
      );
      setCurrentCityList(citiesList?.payload);
    }
    if (from === "operated") {
      const citiesList = await dispatch(
        citiesFunction({ id: profileData?.operatedState })
      );
      setOperatedCityList(citiesList?.payload);
    }
  };

  useEffect(() => {
    if (profileData?.currentState?.length > 0) {
      getCities("current");
    }
  }, [profileData?.currentState]);

  useEffect(() => {
    if (profileData?.operatedState?.length > 0) {
      getCities("operated");
    }
  }, [profileData?.operatedState]);

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSave = async () => {
    if (!validateEmail(profileData?.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid Email",
      }));
    }

    if (isError(formErrors)) {
      return;
    }

    const data = await dispatch(
      updateProfileDetailFunction({
        first_name: profileData?.firstName,
        last_name: profileData?.lastName,
        phone_no: profileData?.phoneNumber,
        profession: profileData?.profession || null,
        gender: profileData?.gender?.toUpperCase() || null,
        birthday: profileData?.birthday || null,
        location: {
          address: profileData?.address || null,
          physical_address: profileData?.physicalAddress || null,
          city: profileData?.currentCity,
          postalcode: profileData?.currentZipCode,
        },
        operated_location: {
          city: profileData?.operatedCity,
          postalcode: profileData?.operatedZipCode,
        },
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Profile Details Updated Successfully", "success", {
        position: "top-center",
      });
      handleCloseModal();
      getProfileDetail();
    }
  };

  return (
    <CustomModal
      title={"Edit Personal Details"}
      open={openProfileModal}
      close={handleCloseModal}
      primaryButtonClick={handleSave}
      secondaryButtonClick={handleCloseModal}
      primaryButtonText={"Update"}
      primaryButtonLoading={loading}
      primaryButtonDisabled={isEmpty({
        firstName: profileData?.firstName,
        lastName: profileData?.lastName,
        phoneNumber: profileData?.phoneNumber,
        // interests: [],
        // roles: [],
        currentState: profileData?.currentState,
        currentCity: profileData?.currentCity,
        currentZipCode: profileData?.currentZipCode,
        operatedState: profileData?.operatedState,
        operatedCity: profileData?.operatedCity,
        operatedZipCode: profileData?.operatedZipCode,
      })}
    >
      <form onSubmit={handleSave}>
        <div className="flex flex-col gap-5">
          <h2 className="text-lg font-bold text-gray-800">Personal Details</h2>
          <TextInput
            label="First Name"
            name="firstName"
            value={profileData.firstName}
            onChange={handleFormDataChange}
            error={formErrors.firstName}
            errorMsg={formErrors.firstName}
            required={true}
          />
          <TextInput
            label="Last Name"
            name="lastName"
            value={profileData.lastName}
            onChange={handleFormDataChange}
            error={formErrors.lastName}
            errorMsg={formErrors.lastName}
            required={true}
          />
          <TextInput
            label="Address"
            name="address"
            value={profileData.address}
            onChange={handleFormDataChange}
            error={formErrors.address}
            errorMsg={formErrors.address}
          />
          <TextInput
            label="Physical Address"
            name="physicalAddress"
            value={profileData.physicalAddress}
            onChange={handleFormDataChange}
            error={formErrors.physicalAddress}
            errorMsg={formErrors.physicalAddress}
          />
          <TextInput
            label="Profession"
            name="profession"
            value={profileData.profession}
            onChange={handleFormDataChange}
            error={formErrors.profession}
            errorMsg={formErrors.profession}
          />
          <div className="vertical-center space-x-4 ">
            <label className="font-semibold">Gender:</label>
            <label>
              <input
                type="radio"
                name="gender"
                value="male"
                checked={profileData.gender === "male"}
                onChange={handleFormDataChange}
              />
              <span className="pl-1">Male</span>
            </label>
            <label>
              <input
                type="radio"
                name="gender"
                value="female"
                checked={profileData.gender === "female"}
                onChange={handleFormDataChange}
              />
              <span className="pl-1">Female</span>
            </label>
          </div>
          <TextInput
            label="Birthday"
            name="birthday"
            type="date"
            value={profileData.birthday}
            onChange={handleFormDataChange}
            error={formErrors.birthday}
            errorMsg={formErrors.birthday}
          />
        </div>

        <div className="my-4">
          <p className="text-md font-semibold">Current Location</p>
          <Divider />
        </div>

        <div className="flex flex-col gap-5 mb-2">
          <SelectDropdown
            options={statesListing}
            loading={true}
            required={true}
            label="Select State"
            name="currentState"
            value={profileData?.currentState}
            onChange={handleFormDataChange}
          />

          <SelectDropdown
            options={currentCityList}
            disabled={!profileData?.currentState}
            loading={true}
            required={true}
            label="Select City"
            name="currentCity"
            value={profileData?.currentCity}
            onChange={handleFormDataChange}
            description={
              cityLoading
                ? "Loading..."
                : !profileData?.currentState
                ? "Please Select State First"
                : ""
            }
          />

          <TextInput
            label="Zip Code"
            type="number"
            name="currentZipCode"
            required={true}
            value={profileData?.currentZipCode}
            onChange={handleFormDataChange}
          />
        </div>

        <div className="my-4">
          <p className="text-md font-semibold">Operated Location</p>
          <Divider />
        </div>

        <div className="flex flex-col gap-5 mb-2">
          <SelectDropdown
            options={statesListing}
            loading={true}
            required={true}
            label="Select State"
            name="operatedState"
            value={profileData?.operatedState}
            onChange={handleFormDataChange}
          />

          <SelectDropdown
            options={operatedCityList}
            disabled={!profileData?.operatedState}
            required={true}
            label="Select City"
            name="operatedCity"
            value={profileData?.operatedCity}
            onChange={handleFormDataChange}
            description={
              cityLoading
                ? "Loading..."
                : !profileData?.operatedState
                ? "Please Select State First"
                : ""
            }
          />

          <TextInput
            label="Zip Code"
            type="number"
            name="operatedZipCode"
            required={true}
            value={profileData?.operatedZipCode}
            onChange={handleFormDataChange}
          />
        </div>

        <div className="flex flex-col gap-5">
          <h2 className="text-lg font-bold">Contacts</h2>
          <TextInput
            label="Phone Number"
            name="phoneNumber"
            type="number"
            value={profileData.phoneNumber}
            onChange={handleFormDataChange}
            error={formErrors.phoneNumber}
            errorMsg={formErrors.phoneNumber}
            required={true}
          />
        </div>
      </form>
    </CustomModal>
  );
};
