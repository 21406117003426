import React from 'react'
import { useSelector } from 'react-redux';
import { Avatar } from '@nextui-org/react';
import { TriangleIcon } from '../../assets/icons';
import AttachementMessage from './AttachementMessage';

const ChatAreaBody = ({
    message,
    name,
    avatar,
    isSeenMessage,
    messageTime,
    senderId,
    chatType,
    onHover,
    file,
    fileType,
    attachements,
    completeItem
}) => {
    const attachementType = fileType?.startsWith('image/') ? 'image' : fileType?.startsWith('video/') ? 'video' : 'document';
    const currentUserId = useSelector((state) => state?.user?.user?.id);
    const me = Number(currentUserId) === Number(senderId);

    return (
        <div className={`w-full flex mt-2 space-x-3 max-w-xs ${me ? 'ml-auto justify-end' : ''}`}
            onMouseEnter={onHover}>
            {file ?
                <AttachementMessage
                    message={message}
                    name={name}
                    avatar={avatar}
                    isSeenMessage={isSeenMessage}
                    messageTime={messageTime}
                    me={me}
                    chatType={chatType}
                    onHover={onHover}
                    fileType={attachementType}
                    attachements={attachements}
                    completeItem={completeItem}
                />
                :
                <div>
                    {/* Message Container */}
                    {!me && chatType === 'group' &&
                        <div className='w-6 !-mt-2'>
                            <Avatar src={avatar}
                                name={name?.slice(0, 1)?.toLocaleUpperCase() + name?.split(' ')?.[1]?.slice(0, 1)?.toLocaleUpperCase()}
                                className="w-6 h-6 cursor-pointer text-xs bg-opacity-65 bg-gray-500"
                                classNames={{
                                    base: "bg-mediumgrey/30",
                                    icon: "text-black/60",
                                }}
                            />
                        </div>
                    }
                    <div className={`relative h-full p-2 rounded-lg ${me ? 'bg-[#6581B9]' : `bg-[#EFF4FB] -top-3 left-2.5 ${chatType === 'group' && 'ml-6'}`}`}>
                        {/* Message */}
                        <p className={`float-left h-full text-sm !min-w-20 pr-8 ${me ? 'text-white pb-4' : `text-[#767499] ${chatType !== 'group' && '!pb-4'}`} mb-1 `}>
                            {message}
                        </p>
                        {/* Message Date*/}
                        <span className={`absolute right-1.5 bottom-1 text-xs ${me ? 'text-white' : 'text-[#767499]'} leading-none`}>
                            {messageTime ? messageTime : ''}
                        </span>
                        {/* Triangle */}
                        {!me ? (
                            <span>
                                <TriangleIcon
                                    className={
                                        'rotate-[60deg] absolute top-[-5px] -left-1.5'
                                    }
                                    color={'#EFF4FB'}
                                />
                            </span>
                        ) : <span>
                            <TriangleIcon
                                className={
                                    '-rotate-[60deg] absolute -top-[5px] -right-1.5'
                                }
                                color={'#6581B9'}
                            />
                        </span>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default ChatAreaBody
