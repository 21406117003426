/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  SelectDropdown,
  showToast,
  TextInput,
  CustomModal,
  AddMore,
  Spinner,
  Skeleton,
  CustomCheckbox,
} from "../../../../components/shared";
import { DeleteIcon, LongArrow } from "../../../../assets/icons";
import {
  deleteExperienceFunction,
  getExperienceFunction,
  updateExperienceFunction,
} from "../../../../redux/reducers/userReducers/experienceReducer/experienceReducer";
import { jobTypeData } from "../../../../utils/mockupData";
import { isEmpty } from "../../../../utils/validations";
import { capitalize, formatDate } from "../../../../utils/utils";
import {
  deleteProjectFunction,
  getProjectFunction,
  updateProjectFunction,
} from "../../../../redux/reducers/userReducers/projectReducers/projectRecuer";
import {
  citiesFunction,
  statesFunction,
} from "../../../../redux/reducers/authReducers/statesReducer";
import { ProfileDeleteModal } from "../DeleteModal";

// Initial Experience Data
const initialExperienceData = {
  jobType: "",
  jobTitle: "",
  company: "",
  startDate: "",
  endDate: "",
  isOngoing: false,
  proofUpload: "",
};

// Initial Project Data
const initialProjectData = {
  projectName: "",
  state: "",
  city: "",
  isOngoing: false,
  endDate: "",
  startDate: "",
  proofUpload: "",
};

export const Experience = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cityLoading } = useSelector((state) => state?.states);
  const { experiences: experienceListing, loading: experienceLoading } =
    useSelector((state) => state?.experience);
  const { projects: projectsListing, loading: projectsLoading } = useSelector(
    (state) => state?.projects
  );
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [experience, setExperience] = useState(initialExperienceData);
  const [project, setProject] = useState(initialProjectData);
  const [statesListing, setStatesListing] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  // Get Experience
  const getExperience = async () => {
    await dispatch(getExperienceFunction());
  };

  // Get Projects
  const getProjects = async () => {
    await dispatch(getProjectFunction());
  };

  // Get States
  const getStates = async () => {
    const statesList = await dispatch(statesFunction());
    setStatesListing(statesList?.payload);
  };

  useLayoutEffect(() => {
    getExperience();
    getProjects();
    getStates();
  }, []);

  // Get Cities Listing
  const getCities = async () => {
    const citiesList = await dispatch(
      citiesFunction({ id: project?.state[0] })
    );
    setCitiesList(citiesList?.payload);
  };

  useLayoutEffect(() => {
    if (!project?.state) return;
    getCities();
  }, [project?.state]);

  // Handle Experience Change
  const handleExperienceChange = (event) => {
    const { name, value } = event.target;
    setExperience((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Project Change
  const handleProjectChange = (event) => {
    const { name, value } = event.target;
    setProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addExperience = async () => {
    const formData = new FormData();
    formData.append("job_title", experience?.jobTitle);
    formData.append(
      "type",
      jobTypeData[experience?.jobType[0] - 1]?.name?.toUpperCase()
    );
    formData.append("employer", experience?.company);
    formData.append("start_date", experience?.startDate);
    formData.append("is_verified", true);
    formData.append("is_ongoing", experience?.isOngoing);

    if (experience?.endDate?.trim() !== "") {
      formData.append("end_date", experience.endDate);
    }
    if (experience?.proofUpload) {
      formData.append("file_upload", experience.proofUpload);
    }

    const data = await dispatch(updateExperienceFunction(formData));

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenExperienceModal(false);
      showToast("Experience Added Successfully", "success", {
        position: "top-center",
      });
      getExperience();
      setExperience(initialExperienceData);
    } else if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join()).join("\n"),
        "error",
        { position: "top-center" }
      );
    }
  };

  const addProject = async () => {
    const formData = new FormData();
    formData.append("project_name", project?.projectName);
    formData.append("location.city", project?.city);

    formData.append("start_date", project?.startDate);
    formData.append("is_verified", true);
    formData.append("is_ongoing", project?.isOngoing);

    if (!project?.isOngoing && project?.endDate?.trim() !== "") {
      formData.append("end_date", project.endDate);
    }
    if (project?.proofUpload) {
      formData.append("file_upload", project.proofUpload);
    }

    const data = await dispatch(updateProjectFunction(formData));

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenProjectModal(false);
      showToast("Project Added Successfully", "success", {
        position: "top-center",
      });
      getProjects();
      setProject(initialProjectData);
    } else if (data?.error) {
      showToast(
        data?.payload?.map((error) => Object.values(error).join()).join("\n"),
        "error",
        { position: "top-center" }
      );
    }
  };

  // Close Experience Modal
  const handleCloseExperienceModal = () => {
    setOpenExperienceModal(false);
    setExperience(initialExperienceData);
  };

  // Close Project Modal
  const handleCloseProjectModal = () => {
    setOpenProjectModal(false);
    setProject(initialProjectData);
  };

  // Delete Experience
  const deleteExperience = async (id) => {
    const data = await dispatch(
      deleteExperienceFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Experience Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getExperience();
    }
  };

  // Delete Project
  const deleteProject = async (id) => {
    const data = await dispatch(
      deleteProjectFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Project Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getProjects();
    }
  };

  // Handle checkbox
  const handleCheckbox = (checked) => {
    if (checked === true) {
      setProject((prev) => ({
        ...prev,
        endDate: "",
      }));
    }
    setProject((prev) => ({
      ...prev,
      isOngoing: checked,
    }));
  };

  const handleExperienceCheckbox = (checked) => {
    if (checked === true) {
      setExperience((prev) => ({
        ...prev,
        endDate: "",
      }));
    }
    setExperience((prev) => ({
      ...prev,
      isOngoing: checked,
    }));
  };

  // Handle file change for proofUpload
  const handleExperienceFileChange = (event) => {
    const file = event.target.files[0];
    setExperience((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  // Handle file change for proofUpload
  const handleProjectFileChange = (event) => {
    const file = event.target.files[0];
    setProject((prevState) => ({
      ...prevState,
      proofUpload: file,
    }));
  };

  return (
    <div>
      {/* Experience */}
      <div>
        <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
          <div className="vertical-center gap-3">
            <LongArrow
              className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
              onClick={() => navigate(-1)}
            />
            <h3 className="text-xl">Experience</h3>
          </div>
          <div
            onClick={() => setOpenExperienceModal(true)}
            className="flex justify-center items-center gap-3 cursor-pointer"
          >
            <AddMore size={"size-7"} />
            <h3 className="text-xl">Add Experience</h3>
          </div>
        </div>

        <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
          <div className="flex flex-col gap-6 mt-4">
            {experienceLoading ? (
              <>
                {Array.from({ length: 2 }, (_, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b border-grey/30 last:border-0 pb-5"
                    >
                      <div className="font-nunito vertical-center justify-between">
                        <Skeleton className="h-5 w-52 rounded-full" />
                      </div>
                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-12 rounded-full" />
                        <Skeleton className="h-4 w-12 rounded-full" />
                      </div>

                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-16 rounded-full" />
                        <Skeleton className="h-4 w-16 rounded-full" />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {experienceListing?.length > 0 ? (
                  experienceListing?.map((experience, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between">
                          <h2>{experience?.job_title}</h2>
                          <div className="center gap-3">
                            <DeleteIcon
                              className="cursor-pointer hover:text-danger"
                              onClick={() =>
                                setDeleteModal({
                                  open: true,
                                  data: {
                                    id: experience?.id,
                                    name: "Experience",
                                    onClick: deleteExperience,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>
                            {experience?.employer
                              ?.split(" ")
                              ?.map((item) => capitalize(item))
                              ?.join(" ")}
                          </p>
                          {"|"}
                          <p>
                            {experience?.type
                              ?.toLowerCase()
                              ?.split(" ")
                              ?.map((item) => capitalize(item))
                              ?.join(" ")}
                          </p>
                        </div>

                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(experience?.start_date)}</p>
                          {"-"}
                          <p>
                            {experience?.is_ongoing
                              ? "Present"
                              : formatDate(experience?.end_date)}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center font-semibold pb-3">
                    No Data Found
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Projects */}
      <div className="mb-6">
        <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
          <h3 className="text-xl">Projects</h3>

          <div
            onClick={() => setOpenProjectModal(true)}
            className="flex justify-center items-center gap-3 cursor-pointer"
          >
            <AddMore size={"size-7"} />
            <h3 className="text-xl">Add Project</h3>
          </div>
        </div>

        <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
          <div className="flex flex-col gap-6 mt-4">
            {projectsLoading ? (
              <>
                {Array.from({ length: 2 }, (_, index) => {
                  return (
                    <div
                      key={index}
                      className="border-b border-grey/30 last:border-0 pb-5"
                    >
                      <div className="font-nunito vertical-center justify-between">
                        <Skeleton className="h-5 w-52 rounded-full" />
                      </div>
                      <div className="vertical-center gap-2 text-sm text-grey mt-2">
                        <Skeleton className="h-4 w-12 rounded-full" />
                        <Skeleton className="h-4 w-12 rounded-full" />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {projectsListing?.length > 0 ? (
                  projectsListing?.map((project, index) => {
                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between mb-1">
                          <h2>{capitalize(project?.project_name)}</h2>
                          <div className="center gap-3">
                            <DeleteIcon
                              className="cursor-pointer hover:text-danger"
                              onClick={() =>
                                setDeleteModal({
                                  open: true,
                                  data: {
                                    id: project?.id,
                                    name: "Project",
                                    onClick: deleteProject,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{project?.proj_exp_city}</p>
                        </div>
                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{formatDate(project?.start_date)}</p>
                          {"-"}
                          <p>
                            {project?.is_ongoing
                              ? "Present"
                              : formatDate(project?.end_date)}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center font-semibold py-3">
                    No Data Found
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Experience Modal */}
      <CustomModal
        title={"Add Experience"}
        open={openExperienceModal}
        close={handleCloseExperienceModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addExperience} className="space-y-4">
          <div className="flex flex-col gap-5">
            <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
              <p className="font-bold text-sm text-primary">Experience</p>
              {/* <p className="font-bold text-sm text-darkgray">*Required</p> */}
            </div>
            <div className="vertical-center gap-2">
              <h2 className="text-lg font-bold text-gray-800">Experience</h2>
              {experienceLoading ? <Spinner /> : null}
            </div>

            <div className={`space-y-4`}>
              <SelectDropdown
                options={jobTypeData}
                label="Select Job Type"
                name="jobType"
                value={experience?.jobType}
                onChange={handleExperienceChange}
                required={true}
              />

              <TextInput
                label="Job Title"
                name="jobTitle"
                required={true}
                value={experience.jobTitle}
                onChange={handleExperienceChange}
              />
              <TextInput
                label="Company/Employer"
                name="company"
                value={experience.company}
                onChange={handleExperienceChange}
                required={true}
              />

              <div className="flex gap-4">
                <TextInput
                  label="Start Date"
                  name="startDate"
                  type="date"
                  value={experience.startDate}
                  onChange={handleExperienceChange}
                  max={new Date().toISOString().split("T")[0]}
                  required={true}
                />
                <TextInput
                  label="End Date"
                  name="endDate"
                  type="date"
                  value={experience?.endDate}
                  onChange={handleExperienceChange}
                  min={experience?.startDate}
                  max={new Date().toISOString().split("T")[0]}
                  required={true}
                  disabled={experience?.isOngoing != "" ? true : false}
                />
              </div>

              <div className="pl-1 pb-1">
                <CustomCheckbox
                  label="Ongoing"
                  checked={experience?.isOngoing}
                  onChange={handleExperienceCheckbox}
                />
              </div>

              <div className="mb-1">
                <label
                  htmlFor="proofUpload"
                  className="block text-[14px] font-medium text-mediumgray"
                >
                  Upload document
                </label>
                <input
                  type="file"
                  id="proofUpload"
                  name="proofUpload"
                  onChange={handleExperienceFileChange}
                  className="mt-1 block w-fit text-sm text-mediumgray"
                />
              </div>
            </div>

            <AddMore
              text="Add Experience"
              onClick={addExperience}
              disabled={isEmpty({
                name: experience?.jobType,
                state: experience?.jobTitle,
                city: experience?.company,
                startDate: experience?.startDate,
                ...(experience?.isOngoing && {
                  isOnGoing: experience?.isOngoing,
                }),
                ...(!experience?.isOngoing && { endDate: experience?.endDate }),
              })}
            />
          </div>
        </form>
      </CustomModal>

      {/* Projects Modal */}
      <CustomModal
        title={"Add Projects"}
        open={openProjectModal}
        close={handleCloseProjectModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={addProject} className="space-y-4">
          <div className="flex flex-col gap-5">
            <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
              <p className="font-bold text-sm text-primary">Projects</p>
              {/* <p className="font-bold text-sm text-darkgray">*Required</p> */}
            </div>
            <h2 className="text-lg font-bold text-gray-800">Projects</h2>

            <div className={`space-y-4`}>
              <TextInput
                label="Project Name"
                name="projectName"
                value={project.projectName}
                onChange={handleProjectChange}
                required={true}
              />

              <SelectDropdown
                options={statesListing}
                label="Select State"
                name="state"
                value={project?.state}
                onChange={handleProjectChange}
                required={true}
              />
              <SelectDropdown
                options={citiesList}
                disabled={!project?.state}
                label="Select City"
                name="city"
                value={project?.city}
                onChange={handleProjectChange}
                description={
                  cityLoading ? "Loading..." : "Please Select State First"
                }
                required={true}
              />
            </div>

            <div className="flex gap-4">
              <TextInput
                label="Start Date"
                name="startDate"
                type="date"
                value={project.startDate}
                onChange={handleProjectChange}
                max={new Date().toISOString().split("T")[0]}
                required={true}
              />
              <TextInput
                label="End Date"
                name="endDate"
                type="date"
                value={project.endDate}
                onChange={handleProjectChange}
                min={project?.startDate}
                max={new Date().toISOString().split("T")[0]}
                required={true}
                disabled={project?.isOngoing != "" ? true : false}
              />
            </div>

            <div className="pl-1">
              <CustomCheckbox
                label="Ongoing"
                checked={project.isOngoing}
                onChange={handleCheckbox}
              />
            </div>

            <div className="mb-1">
              <label
                htmlFor="proofUpload"
                className="block text-[14px] font-medium text-mediumgray"
              >
                Upload document
              </label>
              <input
                type="file"
                id="proofUpload"
                name="proofUpload"
                onChange={handleProjectFileChange}
                className="mt-1 block w-fit text-sm text-mediumgray"
              />
            </div>
            <AddMore
              text="Add Project"
              onClick={addProject}
              disabled={isEmpty({
                name: project?.projectName,
                state: project?.state,
                city: project?.city,
                startDate: project?.startDate,
                ...(project?.isOngoing && { isOnGoing: project?.isOngoing }),
                ...(!project?.isOngoing && { endDate: project?.endDate }),
              })}
            />
          </div>
        </form>
      </CustomModal>

      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={experienceLoading}
      />
    </div>
  );
};
