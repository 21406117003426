import React, { useState } from "react";

// Local Imports
import {
  AddExperienceIcon,
  CertificationsIcon,
  EducationIcon,
  LicensesIcon,
  ProfileIcon,
  RecommendationIcon,
  SkillsIcon,
} from "../../../assets/icons";
import {
  CustomModal,
  ProfileCard,
  showToast,
  TextInput,
} from "../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isError, validatePassword } from "../../../utils/validations";
import { resetPasswordFunction } from "../../../redux/reducers/authReducers/authReducers";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.user);
  const [openSecurityModal, setOpenSecurityModal] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  // Form Data
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Errors State
  const [formErrors, setFormErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  // Handle Password Change
  const handlePasswordChange = (event) => {
    const { name, value } = event.target;

    if (name === "newPassword") {
      setPasswordCriteria({
        minLength: value.length >= 8,
        hasUppercase: /[A-Z]/.test(value),
        hasLowercase: /[a-z]/.test(value),
        hasNumber: /[0-9]/.test(value),
        hasSpecialChar: /[!@#$%^&*()_\-+=<>?{}[\]~`|\\:;"',./]/.test(value),
      });
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  // Handle Close Modal
  const handleCloseModal = () => {
    setOpenSecurityModal(false);
    setPasswords({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setFormErrors({});
  };

  // Handle Reset Password
  const handleResetPassowrd = async () => {
    if (!passwords?.currentPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        currentPassword: "Current password is required",
      }));
    }

    if (!validatePassword(passwords?.newPassword)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        newPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
    }

    if (!validatePassword(passwords?.confirmPassword)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
    }

    if (passwords?.newPassword !== passwords?.confirmPassword) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword:
          "Invalid Password. Please ensure your password meets the following criteria.",
      }));
      return;
    }

    if (isError(formErrors)) {
      return;
    }

    const data = await dispatch(
      resetPasswordFunction({
        current_password: passwords?.currentPassword,
        new_password: passwords?.newPassword,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      showToast(
        `${
          data?.payload
            ? data?.payload[0]?.current_password
            : "Something went wrong"
        }`,
        "error",
        { position: "top-center" }
      );
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Password updated successfully", "success", {
        position: "top-center",
      });
      handleCloseModal();
    }
  };

  const checkPasswordCriteria = () => {
    if (
      passwordCriteria?.hasLowercase &&
      passwordCriteria?.hasUppercase &&
      passwordCriteria?.hasNumber &&
      passwordCriteria?.hasSpecialChar &&
      passwordCriteria?.minLength
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div>
        <div className="font-nunito mt-4 vertical-center gap-4 bg-white p-2 pl-5 rounded-xl">
          <ProfileIcon width={40} height={40} />
          <div>
            <h3 className="text-xl">Profile</h3>
            <p className="text-darkgrey text-sm">
              Add Information about yourself
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-5">
          <ProfileCard
            icon={<AddExperienceIcon color="black" />}
            iconSX="bg-black/10"
            trackSX="bg-black/10"
            indecatorSX="bg-black"
            title="Experience"
            text="Add job experiences and roles held"
            range={60}
            onClick={() => navigate("/profile/experience")}
          />
          <ProfileCard
            icon={<CertificationsIcon color="#2964E2" />}
            iconSX="bg-black/10"
            trackSX="bg-black/10"
            indecatorSX="bg-secondary"
            title="Certifications"
            text="Add professional certifications earned"
            range={30}
            onClick={() => navigate("/profile/certifications")}
          />

          <ProfileCard
            icon={<EducationIcon color="#34A853" />}
            iconSX={"bg-success/10"}
            trackSX={"bg-success/10"}
            indecatorSX={"bg-success"}
            title={"Education"}
            text={"Add educational qualifications and degrees"}
            range={90}
            onClick={() => navigate("/profile/education")}
          />
          <ProfileCard
            icon={<LicensesIcon color="#D89B00" />}
            iconSX="bg-gold/10"
            trackSX="bg-gold/10"
            indecatorSX="bg-gold"
            title="Licenses"
            text="Add licenses related to your field"
            range={45}
            onClick={() => navigate("/profile/licenses")}
          />
          <ProfileCard
            icon={<RecommendationIcon color="#9F0086" />}
            iconSX="bg-magenta/10"
            trackSX="bg-magenta/10"
            indecatorSX="bg-magenta"
            title="Recommendations"
            text="View professional recommendations received"
            range={70}
            onClick={() => navigate("/profile/recommendations")}
          />

          <ProfileCard
            icon={<SkillsIcon color="#1E009F" />}
            iconSX="bg-primary/10"
            trackSX="bg-primary/10"
            indicatorSX="bg-primary"
            title="Skills"
            text="Showcase your professional skills and expertise"
            range={80}
            onClick={() => navigate("/profile/skills")}
          />
        </div>
      </div>

      {/* Reset Password Modal */}
      <CustomModal
        title={"Security Settings"}
        open={openSecurityModal}
        close={handleCloseModal}
        primaryButtonClick={handleResetPassowrd}
        secondaryButtonClick={handleCloseModal}
        primaryButtonText={"Update"}
        primaryButtonLoading={loading}
        primaryButtonDisabled={isEmpty(passwords) || isError(formErrors)}
      >
        <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
          <p className="font-bold text-sm text-primary">Change Password</p>
        </div>

        <form
          className="flex flex-col gap-5 mb-2"
          onSubmit={handleResetPassowrd}
        >
          <TextInput
            label="Current Password"
            type="password"
            name="currentPassword"
            value={passwords?.currentPassword}
            onChange={handlePasswordChange}
            error={formErrors?.currentPassword}
            errorMsg={formErrors?.currentPassword}
          />
          <TextInput
            label="New Password"
            type="password"
            name="newPassword"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
            error={formErrors?.newPassword}
            errorMsg={formErrors?.newPassword}
            description={
              checkPasswordCriteria()
                ? "Password Strength: Strong"
                : "Password Strength: Weak"
            }
          />
          <TextInput
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
            error={formErrors?.confirmPassword}
            errorMsg={formErrors?.confirmPassword}
          />
          <ul className="text-xs select-none ml-1.5 grid-cols-1 xs:grid-cols-2">
            <li className="text-darkgrey text-sm vertical-center gap-2">
              <div
                className={`list-bullet ${
                  passwordCriteria?.minLength ? "bg-success" : "bg-danger"
                }`}
              />
              Min 8 character
            </li>
            <li className="text-darkgrey text-sm vertical-center gap-2">
              <div
                className={`list-bullet ${
                  passwordCriteria?.hasUppercase ? "bg-success" : "bg-danger"
                }`}
              />
              One uppercase letter
            </li>
            <li className="text-darkgrey text-sm vertical-center gap-2">
              <div
                className={`list-bullet ${
                  passwordCriteria?.hasLowercase ? "bg-success" : "bg-danger"
                }`}
              />
              One lowercase letter
            </li>
            <li className="text-darkgrey text-sm vertical-center gap-2">
              <div
                className={`list-bullet ${
                  passwordCriteria?.hasNumber ? "bg-success" : "bg-danger"
                }`}
              />
              One number
            </li>
            <li className="text-darkgrey text-sm vertical-center gap-2">
              <div
                className={`list-bullet ${
                  passwordCriteria?.hasSpecialChar ? "bg-success" : "bg-danger"
                }`}
              />
              One special character
            </li>
          </ul>
        </form>
      </CustomModal>
    </div>
  );
};

export default Profile;
