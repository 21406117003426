/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { ScrollShadow } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Local Imports
import { assets } from "../../../assets/images";
import {
  AuthLayout,
  AvatarWithDetails,
  Button,
  PartnerCard,
  RedirectLink,
  showToast,
  Skeleton,
  CustomModal,
  PartnersProfileButton,
} from "../../../components/shared";
import {
  BagIcon,
  ChatIcon,
  CrossIcon,
  LocationIcon,
  TrueIcon,
} from "../../../assets/icons";
import { getSignupSuggestionsFunction } from "../../../redux/reducers/userReducers/signupSuggestedPartnersReducer/signupSuggestedPartnersReducer";
import {
  getPartnerDetailsFunction,
  updatePartnerStatusFunction,
} from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { capitalize } from "../../../utils/utils";

const Suggestions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state?.signupSuggestions);
  const { partnerDetailsLoading } = useSelector((state) => state?.partners);
  const [openPartnerDetailsModal, setOpenPartnerDetailsModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [partnerDetail, setPartnerDetail] = useState(null);
  const [partnersListing, setPartnersListing] = useState({
    nearest: [],
    suggested: [],
  });

  // Handle Partner Details Modal
  const handleCloseModal = () => {
    setOpenPartnerDetailsModal(false);
    setStatus(null);
  };

  // Get Suggested Partners
  const getSuggestedPartners = async () => {
    const data = await dispatch(getSignupSuggestionsFunction());
    setPartnersListing({
      nearest: data?.payload?.nearest_partners,
      suggested: data?.payload?.suggested_partners,
    });

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", {
        position: "top-center",
      });
      return;
    }
  };

  // Get Partner Details
  useLayoutEffect(() => {
    getSuggestedPartners();
  }, []);

  // Get Partner Details Function
  const getPartnerDetails = async (id) => {
    const data = await dispatch(
      getPartnerDetailsFunction({
        id: id,
      })
    );
    setPartnerDetail(data?.id);
  };

  // Handle Partner Status Change
  const handlePartnerStatusChange = (status, id) => {
    setStatus(status);
    updatePartnerStatus(status, id);
  };

  // Update Partner Status
  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id: id,
        status: status?.toUpperCase(),
      })
    );
    if (data?.meta?.requestStatus === "fulfilled") {
      handleCloseModal();
      getSuggestedPartners();
    }
  };

  return (
    <AuthLayout>
      <div className="w-full h-full center">
        <div className="bg-white sm:rounded-lg w-full sm:w-[30rem] h-full sm:max-h-[700px] sm:min-h-[300px] overflow-auto px-5 py-5 my-10 shadow-lg">
          <div className="">
            <div className="font-nunito flex items-baseline justify-center mb-4">
              <img src={assets.logo} alt="logo" className="size-12" />
              <p className="text-gold mt-3 text-2xl font-bold tracking-wide w-fit">
                ealNest.
              </p>
            </div>

            {/* Login Headings */}
            <div className="font-nunito mb-5">
              <p className="text-lg font-bold">Suggested Partners</p>

              <div className="flex items-start gap-4 mt-5">
                {/* <AvatarWithDetails addPartner /> */}

                <ScrollShadow
                  orientation="horizontal"
                  hideScrollBar
                  offset={8}
                  size={80}
                  className="flex items-start gap-4 h-[6rem] overflow-x-auto no-scrollbar"
                >
                  {loading ? (
                    Array.from({ length: 7 }, (_, index) => (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-center gap-2"
                      >
                        <Skeleton className="flex rounded-full w-12 h-12" />
                        <div className="w-full flex flex-col items-center justify-center gap-2">
                          <Skeleton className="h-2 w-3/5 rounded-lg" />
                          <Skeleton className="h-2 w-4/5 rounded-lg" />
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      {partnersListing?.suggested?.map((item) => {
                        return (
                          <AvatarWithDetails
                            key={item.id}
                            src={item?.image || null}
                            name={item?.full_name?.split(" ")[0]}
                            // designation={item?.profession}
                            onClick={() => {
                              setOpenPartnerDetailsModal(true);
                              getPartnerDetails(item?.id);
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                </ScrollShadow>
              </div>
            </div>

            <div className="font-nunito mb-5 vertical-center justify-between">
              <p className="text-xl font-bold">Nearest</p>
              <RedirectLink
                text="See all"
                onClick={() => navigate("/partners")}
              />
            </div>

            <ScrollShadow
              offset={10}
              size={50}
              className="flex flex-col gap-4 h-[calc(100vh-23rem)] sm:h-[20rem] overflow-auto no-scrollbar pb-6"
            >
              {loading ? (
                Array.from({ length: 6 }, (_, index) => (
                  <div
                    key={index}
                    className="w-full flex flex-row items-center justify-center gap-2"
                  >
                    <div className="w-max">
                      <Skeleton className="flex rounded-full size-12" />
                    </div>
                    <div className="w-full flex flex-col gap-2">
                      <Skeleton className="h-2 w-32 rounded-lg" />
                      <Skeleton className="h-2 w-16 rounded-lg" />
                      <Skeleton className="h-2 w-24 rounded-lg" />
                    </div>
                  </div>
                ))
              ) : (
                <>
                  {partnersListing?.nearest?.map((item) => {
                    return (
                      <PartnerCard
                        key={item?.id}
                        src=""
                        name={item?.full_name}
                        designation={item?.profession}
                        address={item?.city_name}
                        // onClick={() => {
                        //   setOpenPartnerDetailsModal(true);
                        //   getPartnerDetails(item?.id);
                        // }}
                        id={item?.id}
                      />
                    );
                  })}
                </>
              )}
            </ScrollShadow>

            <div>
              <Button
                variant={"filled"}
                color={"primary"}
                onClick={() => navigate("/success")}
              >
                Skip for now
              </Button>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        title={`${capitalize(partnerDetail?.first_name)} ${capitalize(
          partnerDetail?.last_name
        )}`}
        open={openPartnerDetailsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        {partnerDetailsLoading ? (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              <Skeleton className="flex rounded-full w-20 h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className="flex flex-col gap-2">
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-full h-3" />
              <Skeleton className="flex rounded-full w-20 h-3" />
            </div>

            <div className="flex flex-col gap-2 items-center justify-center">
              <Skeleton className="flex rounded-full w-32 h-6 mb-4" />

              <div className="vertical-center gap-2">
                <Skeleton className="flex rounded-full w-32 h-3" />
                <Skeleton className="flex rounded-full w-32 h-3" />
              </div>
            </div>

            <div className="center gap-2 mt-6">
              <Skeleton className="flex rounded-md size-20" />
              <Skeleton className="flex rounded-md size-20" />
              <Skeleton className="flex rounded-md size-20" />
            </div>
          </>
        ) : (
          <>
            <div className="font-nunito pb-3 flex justify-between items-center">
              {partnerDetail?.profession ? (
                <div className="flex justify-center items-center gap-1">
                  <BagIcon color={"#85838F"} />
                  <p className="font-bold text-base text-darkGray">
                    {partnerDetail?.profession}
                  </p>
                </div>
              ) : null}

              <div className="flex justify-center items-center gap-1 ml-auto bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                <LocationIcon color={"#D89B00"} />
                <p className="font-bold text-sm text-gold">San Francisco, CA</p>
              </div>
            </div>

            <p className=" font-nunito text-sm pb-3">
              {`${capitalize(partnerDetail?.first_name)} ${capitalize(
                partnerDetail?.last_name
              )}`}{" "}
              is a pioneering figure in the real estate development industry,
              renowned for her innovative approach to sustainable and
              community-focused projects. With over two decades of experience,
              Darius has redefined urban landscapes across the nation, blending
              modern architectural aesthetics with eco-friendly practices.
            </p>

            <div className="font-nunito flex flex-col justify-center items-center pb-3">
              <span className="text-2xl font-extrabold text-primary pb-2 ">
                $150-$200/h
              </span>
              <div className="flex gap-3">
                <span className="text-gold text-sm bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                  Negotiable
                </span>
                <span className="text-gold text-sm bg-gold/10 px-2.5 py-[3px] rounded-[20px]">
                  Fully Remote
                </span>
              </div>
            </div>

            <div className="flex justify-center items-center gap-5">
              <PartnersProfileButton
                icon={<CrossIcon color={"red"} height={24} width={24} />}
                value={"deprioritize"}
                selectedValue={status}
                onChange={(event) =>
                  handlePartnerStatusChange(
                    event.target.value,
                    partnerDetail?.id
                  )
                }
                label="Deprioritize"
              />
              <PartnersProfileButton
                icon={<TrueIcon color={"#1E009F"} />}
                value={"match"}
                selectedValue={status}
                onChange={(event) =>
                  handlePartnerStatusChange(
                    event.target.value,
                    partnerDetail?.id
                  )
                }
                label="Match"
              />
              <PartnersProfileButton
                icon={<ChatIcon color={"#D89B00"} height={21} width={21} />}
                value={"prioritize"}
                selectedValue={status}
                onChange={(event) =>
                  handlePartnerStatusChange(
                    event.target.value,
                    partnerDetail?.id
                  )
                }
                label="Prioritize"
              />
            </div>
          </>
        )}
      </CustomModal>
    </AuthLayout>
  );
};

export default Suggestions;
