import { Input } from "@nextui-org/react";
import { CloseIcon, SearchIcon } from '../../../assets/icons';

const SearchBar = ({ variant, placeholder, startContent, endContent, className, label, radius, value, onValueChange, onKeyDown, onClear, disabled }) => {
    return (
        <Input
            variant={variant}
            label={label}
            classNames={{
                inputWrapper: "!rounded !shadow-none !border-1 !border-secondary02 !h-6",
                input: "placeholder:text-[14px] placeholder:font-[400] placeholder:text-secondary00 !bg-red-400"
            }}
            className={className}
            placeholder={placeholder}
            startContent={startContent ? startContent : <SearchIcon color="#000000" />}
            endContent={endContent ? endContent : value?.length > 0 && <button onClick={onClear}><CloseIcon /></button>}
            radius={radius}
            value={value}
            onValueChange={onValueChange}
            onKeyDown={onKeyDown}
            disabled={disabled}
        />
    )
}

export default SearchBar;