import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReconnectingWebSocket from 'reconnecting-websocket';

const SocketContext = React.createContext(null);

export const useSocket = () => {
    const state = useContext(SocketContext);
    if (!state) {
        throw new Error('useSocket must be used within a SocketProvider');
    }
    return state;
};

export const SocketProvider = ({ children }) => {
    const currentUser = useSelector((state) => state?.user?.user);

    const socketRef = useRef(null);
    const [isSocketReady, setIsSocketReady] = useState(false);
    const [messages, setMessages] = useState([]);
    const [channels, setChannels] = useState([]);
    const [newChannel, setNewChannel] = useState(null);

    // Send Command through WebSocket
    const sendCommand = useCallback((command, payload = {}) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            const data = JSON.stringify({
                command,
                ...payload,
            });
            socketRef.current.send(data);
        } else {
            console.warn('WebSocket is not ready to send commands');
        }
    }, []);

    // Handle Incoming Commands
    const handleIncomingCommand = useCallback(
        (parsedData) => {
            // console.log('Command Received:', parsedData);

            switch (parsedData.command) {
                case 'channels': {
                    console.log('Channels Command Run', parsedData)
                    setChannels(parsedData.channels || []);
                    break;
                }
                case 'messages': {
                    console.log('Messages Command Run', parsedData)
                    setMessages((prevMessages) => parsedData.messages || prevMessages);
                    break;
                }
                case 'new_channel': {
                    console.log('new_channel Command Run', parsedData)
                    setNewChannel(parsedData.channel_id);
                    setChannels((prevChannels) => [parsedData, ...prevChannels]);
                    sendCommand('fetch_messages', { channel_id: parsedData.channel_id });
                    break;
                }
                case 'new_message': {
                    console.log('new_message Command Run', parsedData)
                    setMessages((prevMessages) => {
                        if (prevMessages.some((msg) => msg.channel_id === parsedData.channel_id)) {
                            return [...prevMessages, parsedData];
                        }
                        return prevMessages;
                    });
                    sendCommand('fetch_channels')
                    break;
                }
                case 'mark_seen_messages': {
                    console.log('mark_seen_messages Command Run', parsedData)
                    setChannels((prevChannels) =>
                        prevChannels.map((channel) => ({
                            ...channel,
                            seen: channel.id === parsedData.channel_id ? true : channel.seen,
                        }))
                    );
                    break;
                }
                case 'channel_deleted': {
                    console.log('delete_channel Command Run', parsedData)
                    setChannels((prevChannels) =>
                        prevChannels.filter((channel) => channel.id !== parsedData.channel_id)
                    );
                    sendCommand('fetch_channels')
                    break;
                }
                default:
                    console.warn('Unknown command:', parsedData.command);
            }
        },
        [sendCommand]
    );

    useEffect(() => {
        if (!currentUser) return;

        const ws = new ReconnectingWebSocket(`wss://dealnest-api.testerp.co/ws/chat/${currentUser?.id}/`);
        socketRef.current = ws;

        ws.onopen = () => {
            console.log('WebSocket connection established');
            setIsSocketReady(true);
        };

        ws.onmessage = (event) => {
            try {
                const parsedData = JSON.parse(event.data);
                handleIncomingCommand(parsedData);
            } catch (error) {
                console.error('Failed to parse WebSocket message:', error);
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = (event) => {
            console.log('WebSocket connection closed:', event.reason);
            setIsSocketReady(false);
        };

        return () => {
            console.log('Cleaning up WebSocket...');
            ws.close();
            setIsSocketReady(false);
            socketRef.current = null;
        };
    }, [currentUser, handleIncomingCommand]);

    return (
        <SocketContext.Provider value={{ sendCommand, newChannel, messages, setMessages, channels, isSocketReady }}>
            {children}
        </SocketContext.Provider>
    );
};
