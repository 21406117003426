import React, { useState } from "react";
import { AvatarWithDetails } from "../AvatarWithDetails";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CrossIcon, TrueIcon } from "../../../assets/icons";
import { updatePartnerStatusFunction } from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import { CustomTooltip } from "../Tooltip";
import { Skeleton } from "../Skeleton";

export const TopRatedCard = ({ list, fetchTopRatedList, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setSelectedStatuses] = useState({});

  const handleStatusChange = (status, id, event) => {
    event.stopPropagation();
    setSelectedStatuses((prev) => ({
      ...prev,
      [id]: prev[id] === status ? null : status,
    }));
    updatePartnerStatus(status, id);
  };

  const updatePartnerStatus = async (status, id) => {
    const data = await dispatch(
      updatePartnerStatusFunction({
        id,
        status: status?.toUpperCase(),
      })
    );

    if (data.meta.requestStatus === "fulfilled") {
      fetchTopRatedList();
    }
  };

  return (
    <div className="flex space-x-4">
      {loading ? (
        Array.from({ length: 15 }, (_, index) => (
          <Skeleton key={index} className="w-28 h-40 rounded-md" />
        ))
      ) : (
        <>
          {list?.map((item) => {
            const selectedStatus = status[item.id];
            return (
              <div
                key={item.id}
                className="w-28 h-40 flex flex-col justify-between items-center pt-6 rounded-lg bg-white border-2 transition duration-300 ease-in-out transform hover:shadow-lg cursor-pointer"
                onClick={() => navigate(`/partners/partner-detail/${item.id}`)}
              >
                <AvatarWithDetails
                  src={`https://randomuser.me/api/portraits/men/${item.id}.jpg`}
                  name={item.first_name}
                  designation={item.profession}
                />

                <div className="flex gap-2 mb-2">
                  <CustomTooltip content={"Match"}>
                    <div
                      onClick={(e) => handleStatusChange("match", item.id, e)}
                      className={`p-0.5 rounded-lg border-[1.5px] ${
                        selectedStatus === "match"
                          ? " border-blue-500"
                          : " border-lightgray"
                      }`}
                    >
                      <TrueIcon
                        color={selectedStatus === "match" ? "#1E009F" : "gray"}
                        width={18}
                        height={18}
                      />
                    </div>
                  </CustomTooltip>

                  <CustomTooltip content={"Skip"}>
                    <div
                      onClick={(e) =>
                        handleStatusChange("deprioritize", item.id, e)
                      }
                      className={`p-0.5 rounded-lg border-[1.5px] ${
                        selectedStatus === "deprioritize"
                          ? " border-red-500"
                          : "border-lightgray"
                      }`}
                    >
                      <CrossIcon
                        color={
                          selectedStatus === "deprioritize" ? "red" : "gray"
                        }
                        width={20}
                        height={20}
                      />
                    </div>
                  </CustomTooltip>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
