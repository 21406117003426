import React from "react";

// Local Imports
import { Skeleton } from "../../../../../components/shared";
import { capitalize, formatDate } from "../../../../../utils/utils";

export const Experiences = ({ loading, profileData }) => {
  const { experiences } = profileData;

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 mt-6 select-none">
            {experiences?.map((experience, index) => {
              const date = [];
              date.push(
                formatDate(experience?.start_date || ""),
                formatDate(experience?.end_date || "")
              );
              const employer = [];
              employer.push(
                capitalize(experience?.job_title),
                capitalize(experience?.type)
              );
              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-24"
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold">
                      {experience?.job_title}
                    </p>
                    <p
                      className={`text-[10px] ${
                        experience?.verification === "PENDING"
                          ? "bg-gold/30"
                          : experience?.verification === "VERIFIED"
                          ? "bg-success"
                          : "bg-danger text-white"
                      } rounded-full w-fit px-3 py-1`}
                    >
                      {experience?.verification}
                    </p>
                  </div>
                  <p className="text-sm text-grey">
                    {employer?.filter(Boolean)?.join(" - ")}
                  </p>
                  <p className="text-sm text-grey">
                    {date?.filter(Boolean)?.join(" - ")}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
