import React, { useCallback, useState } from 'react'
import { Button, CustomModal, TextInput } from '../shared'
import SearchBar from '../shared/SearchBar';
import GroupMemberSelection from './GroupMemberSelection';
import { SendIcon } from '../../assets/icons';

const GroupChatModal = ({
    openGroupChatModal,
    setOpenGroupChatModal,
    allUsersMembers,
    setAllUsersMembers,
    createNewGroupChat
}) => {
    const [searchQuery, setSearchQuery] = useState(null);
    const [searchUserListing, setSearchUserListing] = useState([]);
    const [groupName, setGroupName] = useState('');

    const handleChatSearch = useCallback(
        (value) => {
            if (!value) {
                setSearchQuery(value)
                setSearchUserListing([])
            } else {
                const searchListingArray = allUsersMembers?.filter((user) => user?.name?.toLowerCase()?.includes(value?.toLowerCase()))
                setSearchQuery(value)
                setSearchUserListing([...searchListingArray])
            }
        },
        [allUsersMembers]
    );

    // Create New Group Chat
    const handleCreateGroupChat = () => {
        const ids = allUsersMembers?.filter(item => item?.selected)?.map(item => item?.id)
        createNewGroupChat(ids, groupName)
        setOpenGroupChatModal(false);
        setGroupName('');
        setAllUsersMembers((prev) => prev.map((item) => ({ ...item, selected: false })))
        setSearchQuery(null);
    };

    return (
        <div>
            <CustomModal
                title={"Add New Group Chat"}
                open={openGroupChatModal}
                close={() => {
                    setOpenGroupChatModal(false);
                    setGroupName('');
                    setAllUsersMembers((prev) => prev.map((item) => ({ ...item, selected: false })))
                    setSearchQuery(null);
                }}
                showPrimaryButton={true}
                showSecondaryButton={false}
                primaryButtonColor='primary'
                primaryButtonDisabled={allUsersMembers?.filter(item => item?.selected)?.length >= 2 && groupName?.length > 0 ? false : true}
                primaryButtonText={'Create Group'}
                primaryButtonClick={() => handleCreateGroupChat()}
            >
                <div className="!h-[400px] overflow-hidden">
                    <div className="">
                        <TextInput
                            label="Add Group Name"
                            name="groupName"
                            value={groupName}
                            onChange={e => setGroupName(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='h-full'>
                        <div className="py-4">
                            <SearchBar
                                className={'bg-gray-100 rounded'}
                                variant={'bordered'}
                                value={searchQuery || ''}
                                onValueChange={handleChatSearch}
                                placeholder={"Search"}
                                onClear={() => { setSearchQuery(''); }}
                            />
                        </div>

                        <div className="!h-[calc(100%-110px)] flex-1 !overflow-y-auto overflow-x-hidden">
                            {(searchQuery?.length > 0 ?
                                searchUserListing : allUsersMembers)?.map((item, index) => (
                                    <GroupMemberSelection
                                        key={index + 1}
                                        name={item?.name}
                                        avatar={item?.avatar}
                                        currentIndex={index}
                                        searchUserListing={searchUserListing}
                                        setSearchUserListing={setSearchUserListing}
                                        allUsersMembers={allUsersMembers}
                                        setAllUsersMembers={setAllUsersMembers}
                                        completeItem={item}
                                        isSearchActive={searchQuery?.length > 0}
                                    />
                                ))}
                        </div>

                    </div>
                    <div className='w-full'>
                        <Button
                            className={"!bg-blue-500 !p-2 !rounded !min-h-9 !w-[40px]"}
                        // onClick={() => {
                        //     handleGroupChatCreation()
                        // }}
                        >
                            <SendIcon className="w-4 h-4" />
                        </Button>
                    </div>

                </div>
            </CustomModal>
        </div>
    )
}

export default GroupChatModal
