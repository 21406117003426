import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

const initialState = {
  licenses: [],
  loading: false,
  error: null,
};

// Get Licenses API Function
export const getLicensesFunction = createAsyncThunk(
  'user/getLicenses',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.License);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Licenses API Function
export const updateLicensesFunction = createAsyncThunk(
  'user/updateLicenses',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.License, values);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Licenses API Function
export const deleteLicensesFunction = createAsyncThunk(
  'user/deleteLicenses',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints.License + values.id, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const licensesSlice = createSlice({
  name: 'user/licenses',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    // Get Licenses Reducers
    builder
      .addCase(getLicensesFunction.pending, (state) => {
        state.loading = true;
        state.skills = null;
        state.error = null;
      })
      .addCase(getLicensesFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.licenses = action.payload;  
        state.error = null;
      })
      .addCase(getLicensesFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.licenses = [];  
      })
      .addCase(updateLicensesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLicensesFunction.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateLicensesFunction.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to update license"; 
      })
      .addCase(deleteLicensesFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLicensesFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.licenses = state.licenses.filter(
          (license) => license.id !== action.payload.id
        );
        state.error = null;
      })
      .addCase(deleteLicensesFunction.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to delete license";  
      });
  },
});

export default licensesSlice.reducer;
