import { Input } from "@nextui-org/react";
import { useState } from "react";
import { CloseEyeIcon, EyeIcon } from "../../../assets/icons";

export const TextInput = ({
  value,
  onChange,
  disabled,
  label,
  type,
  name,
  required,
  maxLength,
  minLength,
  error,
  errorMsg,
  endContent,
  startContent,
  description,
  min,
  max,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleInputChange = (event) => {
    const { value } = event.target;

    if (type === "tel") {
      const sanitizedValue = value.replace(/[^0-9+()\- ]/g, "");
      onChange({ target: { name, value: sanitizedValue } });
    } else {
      onChange(event);
    }
  };

  return (
    <Input
      label={label}
      labelPlacement="inside"
      type={type === "password" ? (isVisible ? "text" : "password") : type}
      size="sm"
      name={name}
      isDisabled={disabled}
      value={value}
      onChange={disabled ? () => {} : handleInputChange}
      isRequired={required}
      maxLength={maxLength}
      minLength={minLength}
      isInvalid={error}
      errorMessage={errorMsg}
      description={description}
      min={min}
      max={max}
      classNames={{
        inputWrapper:
          "bg-lightgrey group-data-[disabled=true]:bg-mediumgrey/30 group-data-[disabled=true]:cursor-not-allowed shadow-none rounded-md pb-[2px] group-data-[focus-visible=true]:ring-0",
        label:
          "scale-90 group-data-[focus=true]:-translate-y-2.5 group-data-[focus=true]:scale-75 group-data-[filled=true]:-translate-y-2.5 group-data-[filled=true]:scale-75 font-nunito text-grey",
        input: "font-nunito text-sm placeholder:text-grey leading-4 pb-[4px]",
        errorMessage: "font-nunito text-xs text-danger",
        description: "font-nunito text-xs text-grey",
      }}
      endContent={
        type === "password" ? (
          <div className="my-auto cursor-pointer" onClick={toggleVisibility}>
            {isVisible ? (
              <CloseEyeIcon className="text-grey" />
            ) : (
              <EyeIcon className="text-grey" />
            )}
          </div>
        ) : (
          endContent
        )
      }
      startContent={startContent}
    />
  );
};
