// Library Imports
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

//  Local Imports
import Router from './routes';
import { Toast } from './components/shared';
import { SocketProvider } from './redux/socket/socket';

const App = () => {
  return (
    <SocketProvider>
      <BrowserRouter>
        <Router />
        <Toast />
      </BrowserRouter>
    </SocketProvider>
  );
}

export default App;
