import React from "react";

// Local Imports
import { Skeleton } from "../../../../../components/shared";
import { capitalize } from "../../../../../utils/utils";

export const Projects = ({ loading, profileData }) => {
  const { project_experiences: projects } = profileData;

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {projects?.map((project, index) => {
              const date = [];
              date.push(capitalize(project?.location?.city), project?.year);
              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-20"
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold">
                      {project.project_name}
                    </p>
                  </div>
                  <p className="text-sm text-grey">
                    {date?.filter(Boolean)?.join(" - ")}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
