import React from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@nextui-org/react';
import { Button } from '../Button';

export const CustomModal = ({
  title,
  children,
  open,
  close,
  // Primary Button Properties
  showPrimaryButton = true,
  primaryButtonText,
  primaryButtonClick,
  primaryButtonVariant = "filled",
  primaryButtonColor = "primary",
  primaryButtonLoading = false,
  primaryButtonDisabled = false,

  // Secondary Button Properties
  showSecondaryButton = true,
  secondaryButtonText,
  secondaryButtonClick,
  secondaryButtonVariant = "outlined",
  secondaryButtonColor = "primary",
  secondaryButtonLoading = false,
  secondaryButtonDisabled = false,

  // Overlay Clickable Property
  isDismissable = false,
}) => {
  return (
    <Modal
      backdrop='opaque'
      placement='center'
      isOpen={open}
      onClose={close}
      closeButton={false}
      isDismissable={isDismissable}
      className='select-none bg-white min-w-[300px] max-w-[700px] shadow-xl rounded-lg'
      classNames={{
        backdrop: 'bg-[#292f46]/50 backdrop-opacity-40',
        wrapper: 'h-screen overflow-none',
      }}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className='text-xl font-semibold text-gray-800'>
              <div className='font-nunito'>
                <p className='text-xl font-bold'>{title}</p>
              </div>
            </ModalHeader>
            <ModalBody className='max-h-[500px] overflow-auto text-gray-600'>
              {children}
            </ModalBody>
            <ModalFooter className='flex justify-end'>
              {showSecondaryButton ? (
                <Button
                  className='w-full md:w-auto'
                  variant={secondaryButtonVariant}
                  color={secondaryButtonColor}
                  disabled={secondaryButtonDisabled}
                  loading={secondaryButtonLoading}
                  onClick={secondaryButtonClick || close}
                >
                  {secondaryButtonText || 'Cancel'}
                </Button>
              ) : null}

              {showPrimaryButton ? (
                <Button
                  className='min-w-24 md:w-auto'
                  variant={primaryButtonVariant}
                  disabled={primaryButtonDisabled}
                  color={primaryButtonColor}
                  loading={primaryButtonLoading}
                  onClick={primaryButtonClick || close}
                >
                  {primaryButtonText || 'Save'}
                </Button>
              ) : null}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
