import React from 'react';
import { Avatar } from '@nextui-org/react';
import { CustomCheckbox } from '../shared';

const GroupMemberSelection = ({
    avatar,
    name,
    currentIndex,
    searchUserListing,
    setSearchUserListing,
    allUsersMembers,
    setAllUsersMembers,
    isSearchActive,
}) => {
    const handleCheckboxChange = (checked) => {
        const itemId = isSearchActive
            ? searchUserListing[currentIndex]?.id
            : allUsersMembers[currentIndex]?.id;

        // Update All Users
        setAllUsersMembers((prev) => {
            const updatedList = [...prev];
            const targetIndex = updatedList.findIndex((user) => user.id === itemId);
            if (targetIndex !== -1) {
                updatedList[targetIndex] = {
                    ...updatedList[targetIndex],
                    selected: checked,
                };
            }
            return updatedList;
        });

        // Update Search Users (if active)
        if (isSearchActive) {
            setSearchUserListing((prev) => {
                const updatedList = [...prev];
                const targetIndex = updatedList.findIndex((user) => user.id === itemId);
                if (targetIndex !== -1) {
                    updatedList[targetIndex] = {
                        ...updatedList[targetIndex],
                        selected: checked,
                    };
                }
                return updatedList;
            });
        }
    };

    return (
        <div
            className="flex items-center gap-3 p-4 hover:bg-gray-50 cursor-pointer"
            onClick={() => handleCheckboxChange(!(
                isSearchActive
                    ? searchUserListing[currentIndex]?.selected
                    : allUsersMembers[currentIndex]?.selected
            ))}
        >
            <CustomCheckbox
                checked={isSearchActive
                    ? searchUserListing[currentIndex]?.selected
                    : allUsersMembers[currentIndex]?.selected}
                onChange={(checked) => handleCheckboxChange(checked)}
            />
            <div className="relative flex items-center gap-3">
                <Avatar
                    src={avatar}
                    classNames={{
                        base: "bg-mediumgrey/30",
                        icon: "text-black/60",
                    }}
                />
                <h3 className="font-medium">{name}</h3>
            </div>
        </div>
    );
};

export default GroupMemberSelection;
