import { Badge } from '@nextui-org/react';
import React from 'react';
import { useSocket } from '../../../redux/socket/socket';

export const IconNavigation = ({ icon, text, onClick, row, active }) => {
  const { channels } = useSocket();
  const unReadMessage = channels?.reduce((acc, channel) => acc + channel?.unread_messages, 0)
  return (
    <div
      onClick={onClick}
      className={`flex ${row ? 'items-end' : 'items-center'
        } cursor-pointer select-none w-full ${row ? 'flex-row justify-start gap-3' : 'flex-col justify-center'
        }`}
    >
      {text === 'Chat' && unReadMessage > 0 ? <Badge
        content={unReadMessage}
        size='sm'
        color='danger'
        shape='circle'
        placement='top-right'
        isInvisible={false}
        classNames={{
          badge: '-translate-y-[6px] translate-x-2 text-white text-[10px]',
        }}
        showOutline={false}
      >
        <div className={`${active ? 'text-primary' : 'text-grey'}`}>{icon}</div>
      </Badge> :
        <div className={`${active ? 'text-primary' : 'text-grey'}`}>{icon}</div>
      }
      <h6 className={`font-nunito text-[13px] ${active ? 'text-primary font-bold' : 'text-grey font-semibold'}`}>
        {text}
      </h6>
    </div>
  );
};
