import React from "react";

// Local Imports
import { Skeleton } from "../../../../../components/shared";

const Licences = ({ loading, profileData }) => {
  const { licenses } = profileData;

  return (
    <>
      <div>
        {loading ? (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {Array.from({ length: 3 }).map((_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-2xl" />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 smd:grid-cols-2 lg:grid-cols-3 gap-3 mt-6">
            {licenses?.map((license, index) => {
              return (
                <div
                  key={index}
                  className="bg-lightgrey border border-grey/30 rounded-2xl px-4 py-3 w-full min-h-12   select-none"
                >
                  <div className="vertical-center justify-between">
                    <p className="text-md font-semibold truncate">
                      {license?.name}
                    </p>

                    <p
                      className={`text-[10px] ${
                        license?.verification === "PENDING"
                          ? "bg-gold/30"
                          : license?.verification === "VERIFIED"
                          ? "bg-success"
                          : "bg-danger text-white"
                      } rounded-full w-fit px-3 py-1`}
                    >
                      {license?.verification}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Licences;
