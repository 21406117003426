// Library Imports
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Local Imports
import registerUserSlice from './reducers/authReducers/authReducers';
import rolesInterestsSlice from './reducers/authReducers/rolesInterestsReducer';
import statesSlice from './reducers/authReducers/statesReducer';
import usersListingSlice from './reducers/adminReducers/usersReducer';
import profileDetailsSlice from './reducers/userReducers/editProfileReducers/editProfileReducer';
import skillsSlice from './reducers/userReducers/skillsReducer/skillsReducer';
import experienceSlice from './reducers/userReducers/experienceReducer/experienceReducer';
import projectsSlice from './reducers/userReducers/projectReducers/projectRecuer';
import certificationsSlice from './reducers/userReducers/certificationReducer/certificationReducer';
import licensesSlice from './reducers/userReducers/licensesReducer/licensesReducer';
import recommendationsSlice from './reducers/userReducers/recommendationsReducer/recommendationsReducer';
import educationsSlice from './reducers/userReducers/educationReducer/educationReducer';
import signupSuggestionsSlice from './reducers/userReducers/signupSuggestedPartnersReducer/signupSuggestedPartnersReducer';
import partnersSlice from './reducers/userReducers/partnersReducer/partnersReducer';
import userVerificationSlice from './reducers/adminReducers/userVerificationReducer';


// Define configuration & reducers you want to persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'cities', 'states', 'partners'],
};

// Combine your reducers
const rootReducer = combineReducers({
  user: registerUserSlice,
  rolesInterests: rolesInterestsSlice,
  states: statesSlice,
  usersListing: usersListingSlice,
  profileDetails: profileDetailsSlice,
  skills: skillsSlice,
  experience: experienceSlice,
  projects: projectsSlice,
  certifications: certificationsSlice,
  licenses: licensesSlice,
  recommendations: recommendationsSlice,
  educations: educationsSlice,
  signupSuggestions: signupSuggestionsSlice,
  partners: partnersSlice,
  verification: userVerificationSlice
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Create a persistor
export const persistor = persistStore(store);
