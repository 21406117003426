import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { useDebounce } from "../../../utils/utils";

// Local Imports

export const SelectDropdown = ({
  options,
  label = "Select an option",
  onChange,
  value,
  multipleSelection,
  required = false,
  name,
  disabledKeys,
  disabled = false,
  error = false,
  errorMsg,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const itemsPerPage = 80;
  const totalItems = options?.length;

  // Check if there are more items to load
  const hasMore = page * itemsPerPage < totalItems;

  const onLoadMore = useCallback(() => {
    if (!hasMore) return;
    const nextPage = page + 1;
    const newItems = options?.slice(0, nextPage * itemsPerPage);
    setItems(newItems);
    setPage(nextPage);
  }, [hasMore, options, page]);

  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: isOpen,
    shouldUseLoader: false,
    onLoadMore,
  });

  // Debounced input value
  const [inputValue, setInputValue] = useState("");
  const debouncedInputValue = useDebounce(inputValue, 200);

  // Filter options based on debounced input
  useEffect(() => {
    if (debouncedInputValue) {
      const filteredItems = options?.filter(option =>
        option?.name?.toLowerCase()?.includes(debouncedInputValue?.toLowerCase())
      );
      setItems(filteredItems);
    } else {
      setItems(options?.slice(0, itemsPerPage));
    }
  }, [debouncedInputValue, options]);

  useEffect(() => {
    setItems(options?.slice(0, itemsPerPage));
  }, [options]);

  return (
    <Autocomplete
      label={label}
      size="sm"
      scrollRef={scrollerRef}
      selectionMode={multipleSelection ? "multiple" : "single"}
      isRequired={required}
      isDisabled={disabled}
      disabledKeys={disabledKeys}
      scrollShadowProps={{
        isEnabled: false
      }}
      name={name}
      isInvalid={error}
      errorMessage={errorMsg}
      defaultItems={items}
      selectedKey={value}
      onSelectionChange={(e) => {
        onChange({ target: { name, value: e } })
      }}
      onInputChange={(e) => setInputValue(e)}
      onOpenChange={setIsOpen}
      inputProps={{
        classNames: {
          input: "ml-1",
          inputWrapper: "h-[48px] bg-lightgrey shadow-none rounded-md pb-[2px]",
          label: 'scale-90 group-data-[focus=true]:-translate-y-2.5 group-data-[focus=true]:scale-75 group-data-[filled=true]:-translate-y-2.5 group-data-[filled=true]:scale-75 font-nunito text-grey',
          errorMessage: 'font-nunito text-xs text-danger',
          description: 'font-nunito text-xs text-grey',
        },
      }}
      classNames={{
        popoverContent: 'rounded-md bg-white border border-grey/50',
      }}
      description={description}
    >
      {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
    </Autocomplete>
  );
}