import { Avatar } from '@nextui-org/react'
import React from 'react'
import { DocumentIcon, TriangleIcon } from '../../assets/icons'

const AttachementMessage = ({
    message,
    name,
    avatar,
    isSeenMessage,
    messageTime,
    me,
    chatType,
    fileType,
    attachements,
    completeItem
}) => {
    return (
        <div className={`${chatType === 'group' ? 'pb-1' : ''}`}>
            {/* Message Container */}
            {!me && chatType === 'group' &&
                <span className='w-6 !-mt-2'>
                    <Avatar src={avatar}
                        name={name?.slice(0, 1)?.toLocaleUpperCase() + name?.split(' ')?.[1]?.slice(0, 1)?.toLocaleUpperCase()}
                        className="w-6 h-6 cursor-pointer text-xs bg-opacity-65 bg-gray-500"
                        classNames={{
                            base: "bg-mediumgrey/30",
                            icon: "text-black/60",
                        }}
                    />
                </span>
            }
            <div className={`relative h-full px-2 pt-3 rounded-lg  ${me ? 'bg-[#6581B9] pb-4' : `pb-2 bg-[#EFF4FB] left-2.5 ${chatType === 'group' && 'ml-6 -top-3'}`}`}>
                {fileType === 'image' || fileType === 'video' ? 
                <React.Fragment>
                    {/* images / video */}
                    <div className={`w-full h-[200px] rounded-md bg-slate-900 ${me ? 'mb-2' : `${chatType === 'group'? '!-mb-2 !min-w-[200px]' : '!mb-4 !min-w-[240px]'}`}`}>
                        {fileType === 'image' ? (
                            <img className='w-full h-full object-contain cursor-pointer' src={attachements?.file_url} alt=""
                                onClick={() => window.open(attachements?.file_url, '_blank')}
                            />
                        ) : fileType === 'video' ? (
                            <video className='w-full h-full object-contain rounded-md' controls >
                                <source src={attachements?.file_url} type="video/mp4" />
                            </video>
                        ) : null
                        }
                    </div>

                    {/* Message */}
                    {/* <p className='float-left h-full text-sm pb-4 !min-w-20 pr-8 ${me ? 'text-white' : 'text-black'}'>
                        {message}
                    </p> */}
                    {/* Message Date*/}
                    <span className={`absolute right-3 bottom-1 text-xs ${me ? 'text-white' : 'text-[#767499]'} leading-none`}>
                        {messageTime ? messageTime : ''}
                    </span>
                </React.Fragment>
                    :
                    // Audio/Document
                    <React.Fragment>
                        {fileType === 'audio' ? (
                            <audio className='w-full h-full object-contain' controls>
                                <source src={attachements?.file_url} type="audio/mpeg" />
                            </audio>
                        ) : fileType === 'document' ? (
                            <div className=''>
                                <div className={`w-full h-8 flex items-center justify-between gap-2 px-3 py-1 ${me ? 'mb-4' : `${chatType !== 'group' && '!pt-4 !pb-10'}`} cursor-pointer`} onClick={() => {
                                    window.open(attachements?.file_url, '_blank')
                                }}>
                                    <span className={`${me ? 'text-white' : 'text-[#767499]'} ${chatType === 'group' ? 'max-w-[48vw]' : 'max-w-[55vw]'} underline truncate`}>{attachements?.file_name}</span>
                                    <span className='!w-10 !h-10'>
                                        <DocumentIcon className='!w-10 !h-10' />
                                    </span>
                                </div>
                            </div>
                        ) : null
                        }
                        {/* Message Date*/}
                        <span className={`absolute right-1.5 bottom-1.5 text-xs ${me ? 'text-white' : 'text-[#767499]'} leading-none`}>
                            {messageTime ? messageTime : ''}
                        </span>

                    </React.Fragment>

                }
                {/* Triangle */}
                {!me ? (
                    <span>
                        <TriangleIcon
                            className={
                                'rotate-[60deg] absolute top-[-5px] -left-1.5'
                            }
                            color={'#EFF4FB'}
                        />
                    </span>
                ) : <span>
                    <TriangleIcon
                        className={
                            '-rotate-[60deg] absolute -top-[5px] -right-1.5'
                        }
                        color={'#6581B9'}
                    />
                </span>
                }
            </div>
        </div>
    )
}

export default AttachementMessage
