import React from 'react';
import { Spinner } from '../Spinner';

export const Button = ({ onClick, variant, color, disabled, loading, children, className }) => {

  // Button Variants
  const buttonVariant = {
    filled: `text-white ${disabled ? 'bg-primary cursor-not-allowed' : 'bg-secondary hover:bg-primary cursor-pointer'}`,
    outlined: `border border-primary text-primary bg-white ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`,
    default: `text-white ${disabled ? 'bg-primary cursor-not-allowed' : 'bg-primary hover:bg-primary cursor-pointer'}`,
  }

  // Button Colors
  const buttonColor = {
    primary: `transition ${disabled ? `${variant === 'outlined' ? '!text-primary/50 !border !border-primary/50' : '!bg-secondary/50 !text-white'}` : `${variant === 'outlined' ? '!text-primary !border !border-primary hover:!bg-secondary/10' : '!bg-primary !text-white hover:!bg-secondary'}`}`,
    success: `transition ${disabled ? `${variant === 'outlined' ? '!text-success/50 !border-success/50' : '!bg-success/50 !text-white'}` : `${variant === 'outlined' ? '!text-success !border !border-success hover:!bg-success/10' : '!bg-success !text-white hover:!bg-success/80'}`}`,
    secondary: `transition ${disabled ? `${variant === 'outlined' ? '!text-secondary/50 !border-secondary/50' : '!bg-secondary/50 !text-white'}` : `${variant === 'outlined' ? '!text-secondary hover:!bg-secondary/10' : '!bg-secondary !text-white hover:!bg-primary'} `}`,
    gold: `transition ${disabled ? `${variant === 'outlined' ? '!text-gold/50 !border-gold/50' : '!bg-gold/50 !text-white'}` : `${variant === 'outlined' ? '!text-gold !border-gold hover:!bg-gold/10' : '!bg-gold !text-white hover:!bg-gold/90'} `}`,
    danger: `transition ${disabled ? `${variant === 'outlined' ? '!text-danger/60 !border !border-danger/60' : '!bg-danger/50 !text-white'}` : `${variant === 'outlined' ? '!text-danger !border !border-danger hover:!bg-danger/10' : '!bg-danger !text-white hover:!bg-danger hover:!bg-danger/85'}`}`,
    default: `transition ${disabled ? `${variant === 'outlined' ? '!text-primary !border !border-primary' : '!bg-primary !text-white'}` : `${variant === 'outlined' ? '!text-primary !border !border-primary hover:!bg-primary' : '!bg-primary !text-white hover:!bg-primary'}`}`,
  }

  return (
    <button
      type="submit"
      className={`center w-full min-w-12 rounded-full min-h-[48px] px-6 text-sm font-medium ${buttonVariant[variant] || buttonVariant?.default} ${buttonColor[color] || buttonColor?.default} tracking-widest select-none whitespace-nowrap ${className}`}
      onClick={disabled ? null : onClick}
      disabled={disabled}
    >
      {loading ? <Spinner className={`${variant === 'outlined' ? 'text-secondary' : 'text-white'}`} /> : children}
    </button>
  )
};