/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// icons imports
import { Filter } from "../../../assets/icons";

// Local Imports
import {
  Chip,
  CustomPopover,
  Divider,
  NoDataFound,
  PartnerCard,
  SelectDropdown,
  SignupInterestCheckbox,
  Skeleton,
  TextInput,
} from "../../../components/shared";
import { PartnersIcon } from "../../../assets/icons";
import {
  filterPartnersFunction,
  getPartnersListingFunction,
} from "../../../redux/reducers/userReducers/partnersReducer/partnersReducer";
import {
  citiesFunction,
  statesFunction,
} from "../../../redux/reducers/authReducers/statesReducer";
import { rolesAndInterestsFunction } from "../../../redux/reducers/authReducers/rolesInterestsReducer";

// Initial Filters Data
const filtersData = {
  state: "",
  city: "",
  interests: [],
  profession: "",
  skill: "",
  search: "",
};

const Partner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { cityLoading } = useSelector((state) => state?.states);

  const [openPopover, setOpenPopover] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [formData, setFormData] = useState(filtersData);
  const [debouncedSearch, setDebouncedSearch] = useState(formData.search);

  const getPartnersListing = async () => {
    await dispatch(getPartnersListingFunction());
  };

  const { partnersLoading, partners: partnersListing } = useSelector(
    (state) => state?.partners
  );

  useLayoutEffect(() => {
    getPartnersListing();
    fetchStates();
  }, []);

  const fetchStates = async () => {
    const statesResponse = await dispatch(statesFunction());
    setStatesList(statesResponse?.payload || []);
  };

  const fetchCities = async (stateId) => {
    if (!stateId) return;
    const citiesList = await dispatch(citiesFunction({ id: stateId }));
    setCityList(citiesList?.payload || []);
  };

  const handleStateChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, state: value, city: "" });
    fetchCities(value);
  };

  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, city: value });
  };

  const handleInterestsChange = (name) => {
    const updatedInterests = [...formData?.interests];
    // Check if interest is already selected
    const interestIndex = updatedInterests.indexOf(name);
    // If the interest is not selected
    if (interestIndex === -1) {
      updatedInterests.push(name);
    } else {
      updatedInterests.splice(interestIndex, 1);
    }

    setFormData((prev) => ({
      ...prev,
      interests: updatedInterests,
    }));
  };

  const [interests, setInterests] = useState([]);
  const getRolesInterests = async () => {
    try {
      const rolesAndInterest = await dispatch(rolesAndInterestsFunction());
      const interestsArray = rolesAndInterest?.payload?.interests?.reduce(
        (prev, curr) => [
          ...prev,
          {
            id: curr?.id,
            name: curr?.name?.toLowerCase(),
            label: curr?.name,
            value: false,
          },
        ],
        []
      );

      setInterests(interestsArray);
    } catch (error) {
      console.error("Error fetching roles and interests:", error);
    }
  };

  useLayoutEffect(() => {
    getRolesInterests();
  }, []);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filterOnClick = () => {
    setOpenPopover(false);

    dispatch(
      filterPartnersFunction({
        profession: formData?.profession,
        city: formData?.city,
        skills: [formData?.skill],
        interests: formData?.interests,
        search: formData?.search,
      })
    );
  };

  const handleClearFilter = () => {
    setOpenPopover(false);
    getPartnersListing();
    setFormData(filtersData);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(formData.search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [formData.search]);

  useEffect(() => {
    if (debouncedSearch) {
      filterOnClick();
    } else {
      getPartnersListing();
    }
  }, [debouncedSearch]);

  return (
    <div className="">
      <div className="bg-bggrey pt-4 sticky top-0 z-50 rounded-b-xl">
        <div className="bg-white rounded-xl">
          <div className="flex justify-between items-center pr-5">
            <div className="font-nunito vertical-center gap-4 p-2 py-3 pl-5">
              <PartnersIcon width={30} height={30} />
              <h3 className="text-xl">Partners</h3>
            </div>
            {debouncedSearch ? (
              ""
            ) : (
              <CustomPopover
                open={openPopover}
                setOpen={setOpenPopover}
                placement="bottom-end"
                trigger={
                  <div className="cursor-pointer flex items-center gap-1">
                    <Filter />
                    <h3 className="text-lg mb-[3px]">Filter</h3>
                  </div>
                }
              >
                <div className="gap-3 w-80 flex flex-col items-center bg-white p-2 rounded-md">
                  <div className="flex flex-col gap-3 w-full border-b border-gray-300 pb-2">
                    <div className="vertical-center justify-between">
                      <p className="font-nunito font-semibold text-sm">
                        Filter Options
                      </p>
                      <p
                        onClick={handleClearFilter}
                        className="font-nunito cursor-pointer font-semibold text-sm text-secondary"
                      >
                        Clear
                      </p>
                    </div>
                    <div className="flex flex-col gap-4">
                      <SelectDropdown
                        options={statesList}
                        label="Select State"
                        name="state"
                        value={formData.state}
                        onChange={handleStateChange}
                      />
                      <SelectDropdown
                        options={cityList}
                        disabled={!formData.state}
                        loading={cityLoading}
                        label="Select City"
                        name="city"
                        value={formData.city}
                        onChange={handleCityChange}
                        description={
                          cityLoading
                            ? "Loading..."
                            : !formData.state
                            ? "Please Select State First"
                            : ""
                        }
                      />

                      {/* Intrest  */}
                      <div>
                        <p className="font-normal text-sm pb-1">Interests </p>
                        <div className="flex flex-wrap gap-2">
                          {interests?.map((item) => {
                            const isChecked = formData?.interests.includes(
                              item?.name
                            );
                            return (
                              <SignupInterestCheckbox
                                key={item?.id}
                                name={item?.name}
                                value={isChecked}
                                onChange={() =>
                                  handleInterestsChange(item?.name)
                                }
                              >
                                {item?.label}
                              </SignupInterestCheckbox>
                            );
                          })}
                        </div>
                      </div>

                      {/* profession  */}
                      <div>
                        <p className="font-normal text-sm pb-1">Profession </p>
                        <TextInput
                          label="Enter a profession"
                          type="text"
                          name="profession"
                          value={formData?.profession}
                          onChange={onChange}
                        />
                      </div>

                      {/* Skill  */}
                      <div>
                        <p className="font-normal text-sm pb-1">Skill </p>
                        <TextInput
                          label="Enter a Skill"
                          type="text"
                          name="skill"
                          value={formData?.skill}
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    className="text-sm pb-2 text-secondary font-medium cursor-pointer hover:text-secondary-dark hover:underline transition-colors duration-200 ease-in-out"
                    onClick={filterOnClick}
                  >
                    Apply Filters
                  </p>
                </div>
              </CustomPopover>
            )}
          </div>
          <div className="px-2">
            <Divider />
          </div>
          <div className="flex flex-col base:flex-row mx-1 base:justify-between base:items-center">
            {formData?.search ? (
              ""
            ) : (
              <div className="px-5 py-3 vertical-center flex-wrap gap-2">
                <Link to="/partners/suggestions">
                  <Chip
                    cross={false}
                    variant={
                      location?.pathname === "/partners/suggestions"
                        ? "filled"
                        : "outlined"
                    }
                    text="Suggestions"
                  />
                </Link>
                <Link to="/partners/my-network">
                  <Chip
                    cross={false}
                    variant={
                      location?.pathname === "/partners/my-network"
                        ? "filled"
                        : "outlined"
                    }
                    text="My Network"
                  />
                </Link>
                <Link to="/partners/favorited">
                  <Chip
                    cross={false}
                    variant={
                      location?.pathname === "/partners/favorited"
                        ? "filled"
                        : "outlined"
                    }
                    text="Favorited"
                  />
                </Link>
                <Link to="/partners/nearest">
                  <Chip
                    cross={false}
                    variant={
                      location?.pathname === "/partners/nearest"
                        ? "filled"
                        : "outlined"
                    }
                    text="Nearest"
                  />
                </Link>
              </div>
            )}
            <div
              className={`w-full py-3 mb-1 mx-auto sm:mx-0 ${
                formData?.search ? "py-3" : "base:w-[300px]"
              }`}
            >
              <TextInput
                label="Search"
                type="text"
                name="search"
                value={formData?.search}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      {debouncedSearch ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 base:grid-cols-3  gap-4 pb-6 mt-6">
          {partnersLoading ? (
            Array.from({ length: 15 }, (_, index) => (
              <Skeleton key={index} className="w-full h-24 rounded-3xl" />
            ))
          ) : (
            <>
              {partnersListing?.length > 0 ? (
                partnersListing?.map((item) => {
                  const location = [];
                  location?.push(
                    item?.mailing_address?.city?.name,
                    item?.mailing_address?.state?.name
                  );
                  return (
                    <PartnerCard
                      key={item?.id}
                      src=""
                      name={
                        (item?.first_name || "") + " " + (item?.last_name || "")
                      }
                      designation={item?.profession}
                      address={location?.filter(Boolean)?.join(", ")}
                      id={item?.id}
                      search
                      handleClearFilter={handleClearFilter}
                    />
                  );
                })
              ) : (
                <NoDataFound text={"No Partners Found"} />
              )}
            </>
          )}
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default Partner;
