import * as React from "react";

export const DashboardIcon = ({ width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.616 20q-.672 0-1.144-.472T4 18.385V5.615q0-.67.472-1.143Q4.944 4 5.616 4h12.769q.67 0 1.143.472q.472.472.472 1.144v12.769q0 .67-.472 1.143q-.472.472-1.143.472zm4.884-1v-6.5H5v5.885q0 .269.173.442t.443.173zm1 0h6.885q.269 0 .442-.173t.173-.442V12.5h-7.5zM5 11.5h14V5.616q0-.27-.173-.443T18.385 5H5.615q-.269 0-.442.173T5 5.616z"
    />
  </svg>
);

