import React from 'react';
import { ArrowIcon } from '../../../assets/icons';

export const Stepper = ({
  steps = 1,
  activeStep = 1,
  setActiveStep,
  arrows = false,
  disabledPreviousArrow = false,
  disabledNextArrow = false,
  previousArrow = false,
  nextArrow = false
}) => {
  return (
    <div className="p-1">
      <div className="vertical-center w-full space-x-2">
        {/* Previous Arrow */}
        {arrows || previousArrow ? (
          <div
            className={`rounded-full ${activeStep > 1 && !disabledPreviousArrow ? 'bg-secondary/10 cursor-pointer' : 'bg-mediumgrey/20 cursor-not-allowed'} p-2 transition-all duration-100 center`}
            onClick={() => setActiveStep(prev => prev > 1 ? prev - 1 : prev)}
          >
            <ArrowIcon className={`rotate-180 ${activeStep > 1 ? 'text-gold' : 'text-mediumgrey'}`} />
          </div>
        ) : null}

        {/* Steps */}
        {Array(steps).fill(0).map((_, index) => {
          return (
            <span key={index} className={`flex-auto h-2 rounded-full ${index + 1 < activeStep ? 'bg-secondary' : 'bg-gray-400'} ${index + 1 === activeStep ? '!bg-gold animate-pulse-fast' : ''}`} />
          )
        })}

        {/* Next Arrow */}
        {arrows || nextArrow ? (
          <div
            className={`rounded-full ${activeStep < steps && !disabledNextArrow ? 'bg-secondary/10 cursor-pointer' : 'bg-mediumgrey/20 cursor-not-allowed'} p-2 transition center`}
            onClick={() => setActiveStep(prev => prev < steps ? prev + 1 : prev)}
          >
            <ArrowIcon className={`translate-x-[1px] ${activeStep > 1 ? 'text-mediumgrey' : 'text-gold'}`} />
          </div>
        ) : null}
      </div>
    </div>
  )
};