// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../../network/https';
import { endPoints } from '../../../network/constant';

// Initial state for experinece
const initialState = {
  experiences: [],
  loading: false,
  error: null,
};

// Get Experinece API Function
export const getExperienceFunction = createAsyncThunk(
  'user/getExperience',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.JobExperience);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Experinece API Function
export const updateExperienceFunction = createAsyncThunk(
  'user/udateExperience',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.JobExperience, values);
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Post Experinece API Function
export const deleteExperienceFunction = createAsyncThunk(
  'user/deleteExperience',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(endPoints.JobExperience + values?.id, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const experienceSlice = createSlice({
  name: 'user/experience',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Get Experinece Reducers
    builder
      .addCase(getExperienceFunction.pending, (state) => {
        state.loading = true;
        state.experiences = null;
        state.error = null;
      })
      .addCase(getExperienceFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.experiences = action.payload;
        state.error = null;
      })
      .addCase(getExperienceFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.experiences = null;
      })
      .addCase(updateExperienceFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExperienceFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateExperienceFunction.rejected, (state, action) => {
        state.loading = false;
        state.experiences = null;
      })
      .addCase(deleteExperienceFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExperienceFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteExperienceFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default experienceSlice.reducer;
