import React from 'react';
import { Avatar } from "@nextui-org/react";
import { GroupChatIcon } from '../../assets/icons';

const SideBarBody = ({
    avatar,
    status,
    name,
    lastMessage,
    unReadMessages,
    setActiveChat,
    activeChat,
    completeItem,
    fetchChatMessages,
    setSearchQuery,
    markSeenMessage,
    chatType,
    setMessage,
    setMessages,
    inputRef
}) => {
    return (
        <div className={`hover:bg-gray-100 ${(activeChat?.channel_id && completeItem?.channel_id) && activeChat?.channel_id === completeItem?.channel_id ? 'bg-gray-200' : ''}`}
            onClick={() => {
                setMessage('');
                setMessages([]);
                setTimeout(() => {
                    inputRef?.current?.focus();
                }, 100)
                if (!completeItem.channel_id) {
                    console.log("🚀 ~ if activeChat:", activeChat?.channel_id)
                    console.log("🚀 ~ if completeItem:", completeItem?.channel_id)
                    setActiveChat({ ...completeItem, chatType: 'individual' });
                    setSearchQuery(null)
                } else {
                    console.log("🚀 ~ else activeChat:", activeChat)
                    console.log("🚀 ~ else completeItem:", completeItem?.channel_id)
                    setActiveChat(completeItem);
                    fetchChatMessages(completeItem.channel_id)
                    markSeenMessage(completeItem.channel_id);
                    setSearchQuery(null)
                }
            }
            }

        >
            <div className="flex items-center gap-3 p-4 hover:bg-gray-50 cursor-pointer">
                {chatType === 'group' ?
                    <div className='w-10 h-10 rounded-full bg-mediumgrey/30 flex items-center justify-center'>
                        <GroupChatIcon className='!w-6 !h-6' />
                    </div>
                    :
                    <div className="relative w-10 h-10">
                        <Avatar
                            src={avatar}
                            classNames={{
                                base: "bg-mediumgrey/30",
                                icon: "text-black/60",
                            }}
                        />
                        {status && (
                            <div className="absolute bottom-0 right-0 w-3 h-3 bg-green-500 rounded-full border-2 border-white" />
                        )}
                    </div>
                }

                <div className="flex-1 min-w-0">

                    <h3 className="font-medium">{name}</h3>
                    <div className='flex justify-between items-center w-full gap-1'>
                        <p className="text-sm text-gray-400 truncate">{lastMessage}</p>
                        {unReadMessages > 0 && <div className='mr-0 text-xs text-white flex items-center justify-center !min-w-4 px-2 !h-4 rounded-2xl bg-blue-500'>
                            {unReadMessages}
                        </div>
                        }
                    </div>
                </div>

            </div>
        </div >
    )
}

export default SideBarBody
