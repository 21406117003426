import * as React from "react";
export const DocumentIcon = (props) => (
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{
            enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        {...props}
    >
        <path
            style={{
                fill: "#9187C5",
            }}
            d="M511.344,274.266C511.77,268.231,512,262.143,512,256C512,114.615,397.385,0,256,0S0,114.615,0,256 c0,117.769,79.53,216.949,187.809,246.801L511.344,274.266z"
        />
        <path
            style={{
                fill: "#6A5CAF",
            }}
            d="M511.344,274.266L314.991,77.913L119.096,434.087l68.714,68.714C209.522,508.787,232.385,512,256,512 C391.243,512,501.976,407.125,511.344,274.266z"
        />
        <polygon
            style={{
                fill: "#FFFFFF",
            }}
            points="278.328,333.913 255.711,77.913 119.096,77.913 119.096,311.652 "
        />
        <polygon
            style={{
                fill: "#E8E6E6",
            }}
            points="392.904,311.652 392.904,155.826 337.252,133.565 314.991,77.913 255.711,77.913  256.067,333.913 "
        />
        <polygon
            style={{
                fill: "#FFFFFF",
            }}
            points="314.991,155.826 314.991,77.913 392.904,155.826 "
        />
        <rect
            x={119.096}
            y={311.652}
            style={{
                fill: "#1B0683",
            }}
            width={273.809}
            height={122.435}
        />
        <g>
            <path
                style={{
                    fill: "#FFFFFF",
                }}
                d="M197.492,346.387c16.649,0,28.152,10.746,28.152,26.487c0,15.666-11.655,26.488-28.683,26.488 h-22.25v-52.975H197.492z M188.182,388.615h9.611c8.249,0,14.151-6.357,14.151-15.665c0-9.384-6.205-15.817-14.757-15.817h-9.006 V388.615z"
            />
            <path
                style={{
                    fill: "#FFFFFF",
                }}
                d="M287.481,372.798c0,15.439-12.26,27.169-28.682,27.169c-16.347,0-28.607-11.655-28.607-27.169 c0-15.439,12.26-26.866,28.607-26.866C275.221,345.933,287.481,357.436,287.481,372.798z M243.965,372.874 c0,8.93,6.888,15.817,14.985,15.817c8.173,0,14.681-6.888,14.681-15.817s-6.508-15.665-14.681-15.665 C250.776,357.209,243.965,363.944,243.965,372.874z"
            />
            <path
                style={{
                    fill: "#FFFFFF",
                }}
                d="M341.674,354.863l-7.795,9.233c-3.48-4.238-8.627-6.887-13.32-6.887 c-8.4,0-14.757,6.659-14.757,15.363c0,8.854,6.357,15.589,14.757,15.589c4.466,0,9.611-2.422,13.32-6.206l7.87,8.324 c-5.675,5.827-14.076,9.687-21.871,9.687c-15.969,0-27.849-11.73-27.849-27.245c0-15.287,12.184-26.79,28.305-26.79 C328.203,345.933,336.376,349.413,341.674,354.863z"
            />
        </g>
    </svg>
);
