import React, { forwardRef, useState } from 'react';
import { Tooltip } from "@nextui-org/react";

export const CustomTooltip = forwardRef(({ children, content }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Tooltip
      ref={ref}
      content={content}
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      delay={100}
      closeDelay={0}
      classNames={{
        base: '!rounded-md bg-white border shadow-md',
      }}
    >
      <div>
        {children}
      </div>
    </Tooltip>
  )
});
