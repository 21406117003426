import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { AuthLayout, Button, TextInput, showToast } from '../../../components/shared';
import { assets } from '../../../assets/images';
import { isEmpty, isError, validateEmail } from '../../../utils/validations';
import { forgetPasswordFunction } from '../../../redux/reducers/authReducers/authReducers';


// Reset Password Form Initial Data
const initialResetPasswordData = {
  email: '',
}

// Reset Password Form Errors Initial Data
const initialResetPasswordErrors = {
  email: '',
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.user);
  const [resetFormData, setResetFormData] = useState(initialResetPasswordData);
  const [resetFormErrors, setResetFormErrors] = useState(initialResetPasswordErrors);

  const handleFormDataChange = (event) => {
    setResetFormErrors(prev => ({
      ...prev,
      [event.target.name]: '',
    }))
    setResetFormData(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (resetFormData?.email) {
      if (!validateEmail(resetFormData?.email)) {
        setResetFormErrors(prev => ({
          ...prev,
          email: 'Invalid Email',
        }))
      } else {
        const data = await dispatch(forgetPasswordFunction({ email: resetFormData?.email?.trim() }));

        if (data?.meta?.requestStatus === 'rejected') {
          showToast(`${data?.payload ? data?.payload : 'Something went wrong'}`, 'error', {
            position: 'top-center',
          });
          return
        }

        if (data?.meta?.requestStatus === 'fulfilled') {
          showToast("Kindly check your email to find temporary password", 'success', {
            position: 'top-center',
          });

          setResetFormData({
            email: '',
          })
          navigate('/login')
        }
      }
    }
  }

  return (
    <AuthLayout>
      <div className='w-full h-full center'>
        <div className='bg-white py-5 sm:rounded-lg w-full sm:w-[30rem] sm:h-fit h-full pt-12 sm:pt-5 shadow-lg'>

          <div className='sm:max-h-[80vh] overflow-auto px-5'>
            {/* Logo */}
            <div className='font-nunito flex items-baseline justify-center mb-4'>
              <img src={assets.logo} alt="logo" className='size-12' />
              <p className='text-gold mt-3 text-2xl font-bold tracking-wide w-fit'>ealNest.</p>
            </div>

            {/* Login Headings */}
            <div className='font-nunito mb-5'>
              <p className='text-2xl sm:text-3xl font-bold'>Reset Password</p>
              <p className='text-darkgrey text-sm leading-4 mt-3'>Enter the email below to reset your password. It only takes a few seconds to complete.</p>
            </div>

            {/* Login Form */}
            <form className='flex flex-col gap-5 mb-2' onSubmit={handleResetPassword}>
              <TextInput
                label='Email Address'
                type='email'
                name='email'
                error={resetFormErrors?.email !== ''}
                errorMsg={resetFormErrors?.email}
                value={resetFormData?.email}
                onChange={handleFormDataChange}
              />

              <Button
                variant={'filled'}
                color={'primary'}
                loading={loading}
                disabled={
                  isEmpty(resetFormData) ||
                  isError(resetFormErrors)
                }
                onClick={isEmpty(resetFormData) || isError(resetFormErrors) ? () => { } : handleResetPassword}
              >
                Reset Password
              </Button>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default ForgotPassword