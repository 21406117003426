/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Local Imports
import {
  TextInput,
  AddMore,
  CustomModal,
  showToast,
  Skeleton,
} from "../../../../components/shared";
import {
  deleteEducationsFunction,
  getEducationsFunction,
  updateEducationsFunction,
} from "../../../../redux/reducers/userReducers/educationReducer/educationReducer";
import { isEmpty } from "../../../../utils/validations";
import { DeleteIcon, LongArrow } from "../../../../assets/icons";
import { formatDate } from "../../../../utils/utils";
import { ProfileDeleteModal } from "../DeleteModal";

// Education Initial Data
const initialEducationData = {
  institute: "",
  major: "",
  minor: "",
  honors: "",
  startDate: "",
  endDate: "",
};

export const Education = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { educations: educationsListing, loading: educationsLoading } =
    useSelector((state) => state?.educations);
  const [openEducationModal, setOpenEducationModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [education, setEducation] = useState(initialEducationData);
  const getEducationListing = async () => {
    await dispatch(getEducationsFunction());
  };
  useLayoutEffect(() => {
    getEducationListing();
  }, [dispatch]);

  const handleEducationChange = (event) => {
    const { name, value } = event.target;
    setEducation((prevEducation) => ({
      ...prevEducation,
      [name]: value,
    }));
  };

  const addEducation = async (e) => {
    e.preventDefault();

    const data = await dispatch(
      updateEducationsFunction({
        institution: education?.institute,
        major: education?.major,
        minor: education?.minor,
        honors: education?.honors,
        start_date: education?.startDate,
        end_date: education?.endDate,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      setOpenEducationModal(false);
      showToast("Education Added Successfully", "success", {
        position: "top-center",
      });
      setEducation(initialEducationData);
      getEducationListing();
    }
  };

  const deleteEducation = async (id) => {
    const data = await dispatch(
      deleteEducationsFunction({
        id: id,
      })
    );

    if (data?.meta?.requestStatus === "rejected") {
      const errorMessages = data?.payload
        ? data?.payload?.map((error) => Object.values(error).join())?.join("\n")
        : "Something went wrong";
      showToast(errorMessages, "error", { position: "top-center" });
      return false;
    }

    if (data?.meta?.requestStatus === "fulfilled") {
      showToast("Education Deleted Successfully", "success", {
        position: "top-center",
      });
      setDeleteModal({ open: false, data: null });
      getEducationListing();
    }
  };

  const handleCloseModal = () => {
    setOpenEducationModal(false);
  };

  return (
    <div>
      <div className="font-nunito mt-4 vertical-center justify-between gap-4 bg-white p-2 px-5 rounded-xl">
        <div className="vertical-center gap-3">
          <LongArrow
            className="rotate-180 text-secondary size-[32px] cursor-pointer hover:bg-secondary/10 p-1.5 rounded-full transition"
            onClick={() => navigate(-1)}
          />
          <h3 className="text-xl">Education</h3>
        </div>
        <div
          onClick={() => setOpenEducationModal(true)}
          className="flex justify-center items-center gap-3 cursor-pointer"
        >
          <AddMore size={"size-7"} />
          <h3 className="text-xl">Add Education</h3>
        </div>
      </div>

      <div className="font-nunito mt-4 bg-white p-2 px-5 rounded-xl">
        <div className="flex flex-col gap-6 mt-4">
          {educationsLoading ? (
            <>
              {Array.from({ length: 2 }, (_, index) => {
                return (
                  <div
                    key={index}
                    className="border-b border-grey/30 last:border-0 pb-5"
                  >
                    <div className="font-nunito vertical-center justify-between">
                      <Skeleton className="h-5 w-52 rounded-full" />
                    </div>
                    <div className="vertical-center gap-2 text-sm text-grey mt-2">
                      <Skeleton className="h-4 w-12 rounded-full" />
                      <Skeleton className="h-4 w-12 rounded-full" />
                    </div>

                    <div className="vertical-center gap-2 text-sm text-grey mt-2">
                      <Skeleton className="h-4 w-16 rounded-full" />
                      <Skeleton className="h-4 w-16 rounded-full" />
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {educationsListing?.length > 0 ? (
                <>
                  {educationsListing?.map((education, index) => {
                    // Major and Minor
                    const majorMinor = [];
                    majorMinor.push(education?.major);
                    majorMinor.push(education?.minor);

                    // Duration
                    const duration = [];
                    duration.push(formatDate(education?.start_date));
                    duration.push(formatDate(education?.end_date));

                    return (
                      <div
                        key={index}
                        className="border-b border-grey/30 last:border-0 pb-5"
                      >
                        <div className="font-nunito vertical-center justify-between">
                          <h2 className="text-lg">{education?.institution}</h2>
                          <div className="center gap-3">
                            <DeleteIcon
                              className="cursor-pointer hover:text-danger"
                              onClick={() =>
                                setDeleteModal({
                                  open: true,
                                  data: {
                                    id: education?.id,
                                    name: "Education",
                                    onClick: deleteEducation,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="vertical-center text-sm text-grey">
                          <p>{majorMinor?.filter(Boolean).join(", ")}</p>
                        </div>

                        <div className="vertical-center gap-2 text-sm text-grey">
                          <p>{duration?.filter(Boolean).join(" - ")}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-semibold">No Education Added</p>
              )}
            </>
          )}
        </div>
      </div>

      <CustomModal
        title={"Education"}
        open={openEducationModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className="font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed">
          <p className="font-bold text-sm text-primary">Academic History</p>
          {/* <p className="font-bold text-sm text-darkgray">*Optional</p> */}
        </div>

        <form className="flex flex-col gap-5 mb-2" onSubmit={addEducation}>
          <div className={`space-y-4`}>
            <TextInput
              label="Name of Institute"
              type="text"
              name="institute"
              value={education?.institute}
              onChange={handleEducationChange}
              required={true}
            />
            <TextInput
              label="Major"
              type="text"
              name="major"
              value={education.major}
              onChange={handleEducationChange}
              required={true}
            />
            <TextInput
              label="Minor"
              type="text"
              name="minor"
              value={education.minor}
              onChange={handleEducationChange}
              required={true}
            />
            <div className="flex gap-4">
              <TextInput
                label="Start Date"
                type="date"
                name="startDate"
                max={new Date().toISOString().split("T")[0]}
                value={education.startDate}
                onChange={handleEducationChange}
                required={true}
              />
              <TextInput
                label="End Date"
                type="date"
                name="endDate"
                min={education?.startDate}
                max={new Date().toISOString().split("T")[0]}
                value={education.endDate}
                onChange={handleEducationChange}
                required={true}
              />
            </div>
            <TextInput
              label="Honors"
              type="text"
              name="honors"
              value={education.honors}
              onChange={handleEducationChange}
            />
          </div>

          <AddMore
            text="Add Education"
            onClick={addEducation}
            disabled={isEmpty({
              institute: education?.institute,
              major: education?.major,
              minor: education?.minor,
              startDate: education?.startDate,
              endDate: education?.endDate,
            })}
          />
        </form>
      </CustomModal>
      <ProfileDeleteModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        loading={educationsLoading}
      />
    </div>
  );
};
