import React from "react";
import { assets } from "../../../assets/images";

const TermsAndCondition = () => {
  return (
    <div className="bg-white sm:rounded-lg w-full h-full py-3 shadow-lg overflow-auto">
      <div className="font-nunito flex items-baseline justify-center my-4">
        <img src={assets.logo} alt="logo" className="size-12" />
        <p className="text-gold mt-3 text-2xl font-bold tracking-wide w-fit">
          ealNest.
        </p>
      </div>
      <div className="policy-container">
        <p className="policy-heading pt-2">Kingsmen Capital Terms of Service</p>
      </div>

      <div className="policy-container">
        <p className="policy-text font-bold text-lg">Last modified: 11-3-2024</p>
        <p className="policy-heading">1. Introduction</p>
        <p className="policy-text">
          These Terms of Service ("Terms of Service") are a legal agreement
          between you (“Customer” or “You”) and Kingsmen Capital, LLC.
          (“Kingsmen Capital”) governing your use of this service ("DealNest").
          By accessing or using DealNest, you agree to comply with these Terms
          of Service, which Kingsmen Capital may modify at any time. If you do
          not agree with any part of these Terms of Service, do not use
          theDealNest.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">2. Intellectual Property Rights</p>
        <p className="policy-text">
          All intellectual property rights in DealNest and its contents are
          owned by Kingsmen Capital.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">3. Restrictions</p>
        <p className="policy-text">You may not:</p>
        <ul className="policy-list policy-text mb-4">
          <li>
            Reverse engineer, decompile, disassemble, or otherwise attempt to
            derive the source code of DealNest or API.
          </li>
          <li>
            Reproduce, modify, adapt, translate, or create derivative works
            based on DealNest.
          </li>
          <li>Use DealNest for any illegal, unethical, or harmful purposes.</li>
          <li>
            Share your login credentials or otherwise allow unauthorized access
            to DealNest.
          </li>
          <li>
            Remove, alter, or obscure any copyright, trademark, or other
            proprietary notices from the provided services or on DealNest.
          </li>
          <li>
            Rent, lease, distribute, license, sublicense, sell, transfer,
            assign, distribute or otherwise provide access to DealNest to a
            third party.
          </li>
          <li>
            Incorporate DealNest into any service that you provide to a third
            party
          </li>
          <li>
            Scrape or use web scraping tools to extract data from DealNest.
          </li>
          <li>
            Use DealNest to provide services, or to create a service that
            competes with DealNest.
          </li>
          <li>
            The use of automated systems or software to extract data from
            DealNest through ‘web
          </li>
        </ul>
        <p className="policy-text">
          scraping’ is prohibited unless the third party has directly concluded
          a written license agreement with Kingsmen Capital in which permits it
          access to data within DealNest.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">4. Disclaimer of Warranty</p>
        <p className="policy-text">
          DealNest is provided "as is" without any warranties of any kind,
          whether express or implied. DealNest does not warrant that the
          subscription services will be error-free or uninterrupted. You use
          DealNest at your own risk.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">5. Limitation of Liability</p>
        <p className="policy-text">
          KINGSMEN CAPITAL SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
          SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH
          YOUR USE OF DEALNEST. KINGSMEN CAPITAL'S TOTAL LIABILITY UNDER THESE
          TERMS OF SERVICE SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR YOUR
          SUBSCRIPTION.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">6. Privacy and Security</p>
        <p className="policy-text">
          Kingsmen Capital will collect and process your personal data in
          accordance with its Privacy Policy. By using the DealNest, you consent
          to such data processing practices.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">7. Termination</p>
        <p className="policy-text">
          Kingsmen Capital reserves the right to suspend or terminate your
          access to DealNest at any time for any reason, with or without notice.
          Upon termination, you must cease all use of DealNest.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">8. Governing Law</p>
        <p className="policy-text">
          This Agreement shall be governed by and construed in accordance with
          the laws of the State of Georgia, without giving effect to any
          principles of conflicts of law. Each party waives their right to a
          jury trial.
        </p>
      </div>

      <div className="policy-container">
        <p className="policy-heading">9. Contact Us</p>
        <p className="policy-text">
          f you have any questions about these Terms of Service, please contact
          us at
          <span className="policy-link">info@dealnestio.com.</span>
        </p>
        <p className="policy-text">
          By using the DealNest, you acknowledge that you have read and
          understood these Terms of Service and agree to be bound by its terms
          and conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsAndCondition;
